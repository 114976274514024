import React, { forwardRef } from "react";
import { useWatch } from "react-hook-form";
import * as Styled from "./RHFSwitch.style";
import { RHFSwitchProps } from "./RHFSwitch.types";

export const RHFSwitch = forwardRef<HTMLInputElement, RHFSwitchProps>((props, ref) => {
  const { label, name, onChange, onBlur, disabled, id, errorText, readOnly, required, control } = props;
  const toggled = useWatch({ control, name });

  return (
    <Styled.RHFSwitchContainer>
      <Styled.Label htmlFor={id} disabled={disabled} readOnly={readOnly}>
        <Styled.HiddenCheckbox
          name={name}
          id={id}
          onChange={onChange}
          onBlur={onBlur}
          ref={ref}
          disabled={disabled || readOnly}
        />
        <Styled.RHFSwitch toggled={toggled}>
          <Styled.RHFSwitchHandle toggled={toggled} />
        </Styled.RHFSwitch>
        {label && (
          <span>
            {label}
            {required && <Styled.Required>*</Styled.Required>}
          </span>
        )}
      </Styled.Label>
      {errorText ? (
        <Styled.BottomLabel> {errorText}</Styled.BottomLabel>
      ) : (
        <Styled.BottomLabel> </Styled.BottomLabel>
      )}
    </Styled.RHFSwitchContainer>
  );
});

RHFSwitch.displayName = "RHFSwitch";
