import styled from "styled-components";
import { Icon } from "../Icon";
import {
  ModalOverlayProps,
  ModalHeaderProps,
  ModalBodyProps,
  ModalFooterProps,
  ModalSize,
  ModalProps,
} from "./Modal.types";

export const ModalOverlay = styled.div<ModalOverlayProps>`
  display: ${(p) => (p.open ? "block" : "none")};
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  background: ${(p) => `rgba(0,0,0,${p.theme.opacity.opacity3}`});
	z-index: ${(p) => (p.zIndex ? p.zIndex : "auto")};
`;

export const ModalCard = styled.div<ModalProps>`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: ${(p) => p.size && getSize(p.size)};
  max-height: calc(100% - 20% * 2);
  background-color: ${(p) => p.theme.palette.layout.depth1};
  padding-top: ${(p) => p.theme.spacing.lg};
  padding-bottom: ${(p) => p.theme.spacing.lg};
  padding-left: ${(p) => p.theme.spacing.xl};
  padding-right: ${(p) => p.theme.spacing.xl};
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: flex-start;
  border-radius: ${(p) => p.theme.radius.radius5};
  color: ${(p) => p.theme.palette.lightning.light};
`;

export const ModalHeader = styled.div<ModalHeaderProps>`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  width: 100%;
  font-size: ${(p) => p.theme.fontSize.lg};
  line-height: ${(p) => p.theme.lineHeight.lg};
  font-weight: ${(p) => p.theme.fontWeight.bold};
  padding-bottom: ${(p) => p.theme.spacing.md};
`;
export const ModalBody = styled.div<ModalBodyProps>`
  display: flex;
  justify-content: ${(p) => (p.contentInCenter ? "center" : "flex-start")};
  width: 100%;
  height: calc(100% - 20%);
  max-height: ${(p) => `calc(100% - ${p.theme.spacing.xl}  * 2)`};
  overflow: ${(p) => (p.scrollable ? "auto" : "visible")};
  font-size: ${(p) => p.theme.fontSize.sm};
  line-height: ${(p) => p.theme.lineHeight.sm};
  font-weight: ${(p) => p.theme.fontWeight.regular};
`;

export const ModalScroll = styled.div<{ scrollable: boolean }>`
  height: calc(100% - 20%);
  max-height: ${(p) => `calc(100% - ${p.theme.spacing.xl}  * 2)`};
  overflow: ${(p) => (p.scrollable ? "auto" : "visible")};
  margin: 0 auto;
  width: 100%;
  &::-webkit-scrollbar {
    display: none;
  }
`;

export const ModalFooter = styled.div<ModalFooterProps>`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  flex-direction: row;
  padding-top: ${(p) => p.theme.spacing.xl};
`;

export const ModalIcon = styled(Icon)`
  height: 40px;
  color: ${(p) => p.theme.palette.primary.main};
  padding-bottom: ${(p) => p.theme.spacing.md};
`;

export const getSize = (size?: ModalSize) => {
  switch (size) {
    case "Small":
      return "18.75rem";
    case "Large":
      return "50rem";
    case "Extra large":
      return "71.25rem";
    case "Default":
    default:
      return "31.25rem";
  }
};
