import React from "react";
import {
  StyledAlert,
  StyledAlertContent,
  StyledAlertIcon,
  StyledAlertLabel,
  StyledAlertButton,
  StyledCloseIcon,
} from "./Alert.style";
import { AlertProps, Variants } from "./Alert.types";
import { IconDefinition } from "@fortawesome/fontawesome-svg-core";
import {
  faTrafficCone,
  faThumbsUp,
  faTriangleExclamation,
  faInfoCircle,
  faRemove,
} from "@fortawesome/pro-light-svg-icons";

const getDefaultIcon = (variant: string): IconDefinition => {
  switch (variant) {
    case Variants.DANGER:
      return faTriangleExclamation;
    case Variants.WARNING:
      return faTrafficCone;
    case Variants.INFO:
      return faInfoCircle;
    case Variants.SUCCESS:
    default:
      return faThumbsUp;
  }
};

export const Alert = (props: AlertProps) => {
  const { label, icon, onClose, variant, unclosable = false } = props;
  const defaultIcon = getDefaultIcon(variant);
  const iconToRender = icon || defaultIcon;

  return (
    <StyledAlert variant={variant}>
      <StyledAlertContent>
        <StyledAlertIcon icon={iconToRender} variant={variant} />
        <StyledAlertLabel>{label}</StyledAlertLabel>
      </StyledAlertContent>
      {!unclosable && (
        <StyledAlertButton onClick={onClose}>
          <StyledCloseIcon icon={faRemove} variant={variant} />
        </StyledAlertButton>
      )}
    </StyledAlert>
  );
};

Alert.defaultProps = {
  variant: Variants.SUCCESS,
};
