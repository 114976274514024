import { css, keyframes } from "styled-components";

export const rotate = (start = 0, end: number) => keyframes`
from {
	transform: rotate(${start}deg);
}

to {
	transform: rotate(${end}deg);
}
`;

export const simpleRotation = (end: number, start?: number, ms = 200) => css`
  animation: ${rotate(start, end)} ${ms}ms linear;
`;

export const simpleTransition = (element: string, ms = 200) => `
  transition: ${element} ${ms}ms ease-in;
`;

export const bounce = () => keyframes`
    0% {
      transform: scale(1.2);
    }
    33% {
      transform: scale(1);
    }
    66% {
      transform: scale(1.2);
    }
    100% {
      transform: scale(1);
    }
`;
