import React, { useState } from "react";
import * as Styled from "./Spinner.styles";
import { SpinnerProps } from "./Spinner.types";
import { faCircleNotch } from "@fortawesome/pro-light-svg-icons";

export const Spinner = (props: SpinnerProps) => {
  const { height, color, isPausable = false, icon = faCircleNotch } = props;
  const [paus, setPaus] = useState(false);

  return (
    <Styled.Spinner
      data-testid="spinner"
      height={height}
      isPausable={paus}
      onClick={() => {
        isPausable && setPaus(!paus);
      }}>
      <Styled.Icon icon={icon} height={height} width={height} color={color} />
    </Styled.Spinner>
  );
};
