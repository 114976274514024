import styled from "styled-components";

export const StyledControlButton = styled.button`
  background-color: ${({ theme }) => theme.palette.layout.depth6};
  border: none;
  border-radius: 50%;
  width: 2rem;
  height: 2rem;
  padding: ${({ theme }) => theme.spacing.xs};
  &:hover {
    background-color: ${({ theme }) => theme.palette.layout.depth5};
    cursor: pointer;
  }
  &:not(:last-child) {
    margin-bottom: ${({ theme }) => theme.spacing.xs};
  }
`;
export const MapControlWrapper = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-end;

  margin: ${({ theme }) => theme.spacing.sm};
`;
