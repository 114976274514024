import styled from "styled-components";

export const Img = styled.img`
  display: flex;
  fill: red;
`;

export const ImgWrapper = styled.div`
  display: flex;
  width: 10rem;
`;
