import { DefaultTheme } from "styled-components";
import { IPalette } from "../styled";

export function findPalette(theme: DefaultTheme, key: string): IPalette {
  let palette;
  Object.keys(theme.palette).forEach((k) => {
    if (k.toLowerCase() === key.toLowerCase()) {
      palette = theme.palette[k as keyof typeof theme.palette];
    }
  });
  return palette || theme.palette.primary;
}
