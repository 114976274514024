import styled from "styled-components";
import { Icon } from "../Icon";
import { ColumnTitlesWrapperProps, ColumnTitleProps } from "./Table.types";

export const TableWrapper = styled.div`
  flex: 1;
  width: 100%;
`;

export const TableHeader = styled.div``;

export const Title = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  padding: 12px;
  text-transform: uppercase;
  font-size: ${(p) => p.theme.fontSize.sm};
  line-height: ${(p) => p.theme.lineHeight.sm};
  color: ${(p) => p.theme.palette.layout.depth5};
  border-bottom: 1px solid ${(p) => p.theme.palette.layout.depth4};
`;

export const ColumnTitlesWrapper = styled.div<ColumnTitlesWrapperProps>`
  position: relative;
  display: grid;
  grid-template-columns: repeat(${(p) => p.childCount + 1}, 1fr);
  padding: 10px;
  font-size: ${(p) => p.theme.fontSize.sm};
  line-height: ${(p) => p.theme.lineHeight.sm};
  color: ${(p) => p.theme.palette.layout.depth6};
  border-bottom: 1px solid ${(p) => p.theme.palette.layout.depth4};
`;

export const ColumnTitle = styled.div<{ $sorted: boolean; $sortable: boolean }>`
  display: flex;
  align-items: center;
  gap: ${(p) => p.theme.spacing.xs2};
  font-size: ${(p) => p.theme.fontSize.xs};
  line-height: ${(p) => p.theme.lineHeight.xs};
  font-weight: ${(p) => p.theme.fontWeight.bold};
  color: ${(p) => (p.$sorted ? p.theme.palette.layout.depth6 : p.theme.palette.layout.depth5)};
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  text-transform: uppercase;
  cursor: ${(p) => (p.$sortable ? "pointer" : "auto")};
  &:hover {
    color: ${(p) => p.theme.palette.layout.depth4};
  }
`;

export const PaginationWrapper = styled.div`
  display: flex;
  justify-content: center;
  padding-top: ${(p) => p.theme.spacing.sm};
`;

export const SortIcon = styled(Icon)`
  height: ${(p) => p.theme.lineHeight.sm};
`;

export const NoContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  color: ${(p) => p.theme.palette.layout.depth6};
  padding: ${(p) => p.theme.spacing.xl3};
`;

export const BoxIcon = styled(Icon)`
  height: ${(p) => p.theme.lineHeight.lg};
  color: ${(p) => p.theme.palette.primary.main};
`;

export const NoContentTitle = styled.div`
  font-size: ${(p) => p.theme.fontSize.lg};
  font-weight: bold;
  padding: ${(p) => p.theme.spacing.xs2};
`;

export const NoContentText = styled.div`
  font-size: ${(p) => p.theme.fontSize.sm};
`;

export const ExclamationIcon = styled(Icon)`
  height: ${(p) => p.theme.lineHeight.lg};
  color: ${(p) => p.theme.palette.danger.main};
`;
