import React from "react";
import { createGlobalStyle, DefaultTheme, ThemeProvider as TP, ThemeProviderProps } from "styled-components";
import { defaultTheme } from "./defaultTheme";

import merge from "lodash.merge";

import "../fonts/font.css";

const GlobalStyle = createGlobalStyle`
  body {
    font-family: 'Noto Sans', sans-serif;
    font-weight: 400;
    font-style: normal;
  }
`;

export const ThemeProvider = ({ children, theme }: Partial<ThemeProviderProps<DefaultTheme>>) => {
  const _theme = merge(theme, defaultTheme);
  return (
    <TP theme={_theme}>
      <GlobalStyle />
      {children}
    </TP>
  );
};
