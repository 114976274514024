import styled from "styled-components";
import { Icon } from "../../Icon";
import { NumberSelectorContainerProps, ButtonNumberSelectorType } from "./NumberSelector.types";

export const StyledContainer = styled.div<NumberSelectorContainerProps>`
  display: flex;
  flex-direction: column;
  border-left: 1px solid ${(p) => p.theme.palette.layout.depth4};
  height: 100%;
`;

export const StyledNumberSelector = styled.button<ButtonNumberSelectorType>`
  background-color: ${(p) => p.theme.palette.layout.depth3};
  border: none;
  cursor: pointer;
  position: relative;
  padding: 0 ${(p) => p.theme.spacing.xs};
  height: 100%;

  &:disabled {
    cursor: not-allowed;
    opacity: 0.2;
  }
  &:hover {
    background-color: ${(p) => p.theme.palette.primary.opacity2};
  }
  &:first-child {
    border-top-right-radius: ${(p) => (p.roundedBorders ? p.theme.radius.radius2 : 0)};
    &:before {
      content: "";
      display: block;
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      border-bottom: 1px solid ${(p) => p.theme.palette.layout.depth4};
    }
  }
  &:last-child {
    border-bottom-right-radius: ${(p) => (p.roundedBorders ? p.theme.radius.radius2 : 0)};
  }
`;

export const StyledIcon = styled(Icon)`
  width: 0.8em;
  path {
    fill: ${(p) => p.theme.palette.layout.depth6};
  }
`;
