import React from "react";
import styled from "styled-components";
import { Button } from "../../../Button";
import * as Styled from "./FilterContent.styles";
import { FilterContentProps } from "./FilterContent.types";

const StyledButton = styled(Button)`
margin-right: ${(p) => p.theme.spacing.xs};
margin-left: ${(p) => p.theme.spacing.xs};
`;

const FilterContent = (props: FilterContentProps) => {
  const {
    labelExecute = "Utför",
    labelClear = "Rensa",
    clearFilterCallback,
    executeFilterCallback,
    children,
  } = props;
  return (
    <Styled.FilterContent>
      <Styled.FilterOptions>{children}</Styled.FilterOptions>
      <Styled.FilterButtonWrapper>
        {clearFilterCallback && (
          <StyledButton title={labelClear} variant="Secondary" size="Small" onClick={clearFilterCallback} />
        )}
        {executeFilterCallback && (
          <StyledButton title={labelExecute} variant="Primary" size="Small" onClick={executeFilterCallback} />
        )}
      </Styled.FilterButtonWrapper>
    </Styled.FilterContent>
  );
};

export default FilterContent;
