import styled, { DefaultTheme } from "styled-components";
import { Icon } from "../Icon";
import { VariantProps } from "./Alert.types";
import { findPalette } from "../../utils/styleUtils";

const getVariant = (p: { theme: DefaultTheme } & VariantProps) => {
  return findPalette(p.theme, p.variant);
};

export const StyledAlert = styled.div<VariantProps>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: ${(p) => `${p.theme.spacing.sm} ${p.theme.spacing.lg}`};
  border-radius: ${(p) => p.theme.radius.radius2};
  background-color: ${(p) => getVariant(p).opacity2};
  color: ${(p) => p.theme.palette.danger.contrastText};
`;

export const StyledAlertContent = styled.div`
  display: flex;
	width: 100%;
`;

export const StyledAlertIcon = styled(Icon)<VariantProps>`
  color: ${(p) => getVariant(p).main};
  height: ${(p) => p.theme.spacing.xl};
	width: ${(p) => p.theme.spacing.xl};
`;

export const StyledCloseIcon = styled(Icon)<VariantProps>`
  color: ${(p) => getVariant(p).contrastText};
  height: ${(p) => p.theme.spacing.md};
	width: ${(p) => p.theme.spacing.md};
`;

export const StyledAlertLabel = styled.span`
  margin-left: ${(p) => p.theme.spacing.sm};
  line-height: ${(p) => p.theme.lineHeight.sm};
  font-size: ${(p) => p.theme.fontSize.sm};
`;

export const StyledAlertButton = styled.button`
  background-color: transparent;
  border: none;
  padding: 0;
  cursor: pointer;
`;
