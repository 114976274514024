import styled from "styled-components";
import { TextAreaProps } from "./TextArea.types";

export const Container = styled.div<TextAreaProps>`
  position: relative;
  display: flex;
  flex-direction: column;
  margin-top: ${(p) => (p.cornerHint && p.label ? "2.05rem" : 0)};
  color: ${(p) => p.theme.palette.layout.depth6};
  width: 434px;
`;

export const LabelWrapper = styled.div<{ $onlyCornerHint: boolean }>`
  display: flex;
  justify-content: ${({ $onlyCornerHint }) => ($onlyCornerHint ? "flex-end" : "space-between")};
`;

export const Label = styled.label`
  display: flex;
  font-size: ${(p) => p.theme.fontSize.sm};
  color: ${(p) => p.theme.palette.layout.depth6};
  padding-bottom: ${(p) => p.theme.spacing.sm};
`;

export const CornerHint = styled.span`
  line-height: ${(p) => p.theme.lineHeight.sm};
  font-size: ${(p) => p.theme.fontSize.sm};
  color: ${(p) => p.theme.palette.layout.depth5};
  padding-bottom: ${(p) => p.theme.spacing.sm};
`;

export const Required = styled.div`
  color: ${(p) => p.theme.palette.danger.main};
  margin-left: 4px;
`;

export const BottomLabel = styled.span<{ isError: boolean }>`
  margin-top: ${(p) => p.theme.spacing.sm};
  font-size: ${(p) => p.theme.fontSize.xs};
  font-weight: 400;
  line-height: ${(p) => p.theme.lineHeight.xs};
  color: ${(p) => (p.isError ? p.theme.palette.danger.main : p.theme.palette.layout.depth5)};
`;

export const TextArea = styled.textarea`
  background-color: ${(p) => p.theme.palette.layout.depth3};
  border-radius: ${(p) => p.theme.radius.radius2};
  border: none;
  color: ${(p) => p.theme.palette.layout.depth6};
  line-height: ${(p) => p.theme.lineHeight.sm};
  font-size: ${(p) => p.theme.fontSize.sm};
  font-weight: ${(p) => p.theme.fontWeight.regular};
  padding: ${(p) => p.theme.spacing.md};
  height: 100%;
  min-height: 200px;
  resize: none;
  font-family: inherit;

  &:disabled {
    opacity: ${(p) => !p.readOnly && p.theme.opacity.opacity2};
    cursor: not-allowed;
  }
  &:hover {
    background-color: ${(p) => !p.readOnly && p.theme.palette.primary.opacity1};
  }
  &::placeholder {
    color: ${(p) => p.theme.palette.layout.depth5};
  }

  &:focus-visible {
    outline: none;
    background-color: ${(p) => p.theme.palette.layout.depth3};
  }
  &:focus {
    outline: none;
    background-color: ${(p) => p.theme.palette.layout.depth3};
    box-shadow: ${(p) => !p.readOnly && `0px 0px 4px ${p.theme.palette.primary.main}`};
  }
`;
