import styled from "styled-components";
import { getPadding } from "../../../../utils/size";

export const FilterContent = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${(p) => p.theme.palette.layout.depth2};
  border: 1px solid ${(p) => p.theme.palette.layout.depth7};
  border-radius: ${(p) => p.theme.radius.radius2};
`;

export const FilterOptions = styled.div`
  display: flex;
  flex-direction: row;
	flex-wrap: wrap;
	justify-content: flex-start;
	align-items: flex-start;
	gap: ${(p) => p.theme.spacing.lg};
  padding: ${(p) => getPadding(p.theme)};

	 > * {
		width: 24%;
	}
`;

export const FilterButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
	padding-right: 2.813rem;
	padding-bottom: ${(p) => p.theme.spacing.xl2};
`;
