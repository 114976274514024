import styled, { css } from "styled-components";
import { StyledTabProps } from "./Tabs.types";

export const TabsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  color: ${(p) => p.theme.palette.layout.depth6};
  border-radius: ${(p) => p.theme.radius.radius1};
`;

export const Tabs = styled.div`
  display: flex;
  flex-direction: row;
  box-sizing: border-box;
  height: ${(p => p.theme.inputElementHeight.lg )};
`;

export const TabLabel = styled.div`
  padding: ${(p) => p.theme.spacing.sm} ${(p) => p.theme.spacing.xl} 0;
`;
export const Tab = styled.div<StyledTabProps>`
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  cursor: pointer;
  font-size: ${(p) => p.theme.fontSize.xs};
  font-weight: ${(p) => p.theme.fontWeight.bold};
  background-color: ${(p) => p.theme.palette.layout.depth3};

  border-top-left-radius: ${(p) => p.theme.radius.radius2};
  border-top-right-radius: ${(p) => p.theme.radius.radius2};

  margin-right: ${(p) => p.theme.spacing.xs2};

  &:last-child {
    margin-right: 0;
  }

  ${(p) =>
    p.active
      ? css`
          span {
            background-color: ${(p) => p.theme.palette.primary.main};
          }
        `
      : css`
          &:hover {
            background-color: ${(p) => p.theme.palette.primary.opacity2};
          }
        `}

  span {
    height: ${(p) => p.theme.spacing.xs3};
    margin: 0 auto 0; //${(p) => p.theme.spacing.md}
    background-color: ${(p) => (p.active ? p.theme.palette.primary.main : "none")};
    width: 65%;
    align-self: flex-end;
  }
`;
