import React from "react";
import { CustomIconType, SVGIcon } from "./Icon.types";
import Pinata from "../../assets/svg/pinata-solid.svg";
import ABLogo from "../../assets/svg/logo_assistansbolaget.svg";
import Marker from "../../assets/svg/ico_maker_bg.svg";
import TowingTruck from "../../assets/svg/towing-truck.svg";
import Shield from "../../assets/svg/spinner_shield.svg";

export const customIcons: Record<CustomIconType, React.FC<SVGIcon>> = {
  ABLogo,
  Pinata,
  Marker,
  TowingTruck,
  Shield,
};

export const customIconKeys = Object.keys(customIcons) as CustomIconType[];
