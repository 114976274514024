import styled, { DefaultTheme } from "styled-components";
import { Icon } from "../Icon";
import { BadgeContainerProps, BadgeIconProps, BadgeVariant } from "./Badge.types";

export const BadgeContainer = styled.div<BadgeContainerProps>`
  display: flex;
  width: fit-content;
  justify-content: center;
  align-items: center;
  padding: 0 ${(p) => p.label.length > 1 && p.theme.spacing.xs};
  height: ${(p) => (p.size === "Small" ? p.theme.lineHeight.sm : p.theme.lineHeight.md)};
  min-width: ${(p) => (p.size === "Small" ? p.theme.lineHeight.sm : p.theme.lineHeight.md)};
  color: ${(p) => getTextColorForVariant(p.variant, p.theme)};
  background-color: ${(p) => getBackgroundColorForVariant(p.variant, p.theme)};
  border: none;
  border-radius: ${(p) => p.theme.radius.radius4};
`;

export const Label = styled.div<{ theme: DefaultTheme }>`
  font-size: ${(p) => p.theme.fontSize.sm};
  overflow: hidden;
  white-space: nowrap;
  max-width: 6rem;
  text-overflow: ellipsis;
`;
export const BadgeIcon = styled(Icon)<BadgeIconProps>`
  width: 1rem;
  cursor: pointer;
  margin-left: ${(p) => p.theme.spacing.xs};
`;

const getBackgroundColorForVariant = (variant: BadgeVariant, theme: DefaultTheme) => {
  switch (variant) {
    case "Primary":
      return theme.palette.primary.main;
    case "Danger":
      return theme.palette.danger.opacity3;
  }
};

const getTextColorForVariant = (variant: BadgeVariant, theme: DefaultTheme) => {
  switch (variant) {
    case "Primary":
      return theme.palette.primary.contrastText;
    case "Danger":
      return theme.palette.danger.contrastText;
  }
};
