import styled from "styled-components";
import { Icon } from "../../Icon";
import { TableRowColumnProps, TableRowWrapperProps } from "./TableRow.types";

export const TableRowWrapper = styled.div<TableRowWrapperProps>`
  display: grid;
  grid-template-columns: repeat(${(p) => p.childCount + 1}, 1fr);
  align-items: center;
  padding: 10px;
  border-bottom: 1px solid ${(p) => p.theme.palette.layout.depth4};
  &:hover {
    background-color: ${(p) => p.theme.palette.primary.opacity2};
  }
`;

export const TableRowColumn = styled.div<TableRowColumnProps>`
  color: ${(p) => (p.warning ? p.theme.palette.warning.main : p.theme.palette.layout.depth6)};
  display: inline-block;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  cursor: ${(p) => p.onClick && "pointer"};
`;

export const IconWrapper = styled.div`
  display: flex;
  justify-content: end;
  width: 100%;
`;

export const ActionIcon = styled(Icon)`
  color: ${(p) => p.theme.palette.layout.depth6};
  width: ${(p) => p.theme.lineHeight.sm};
  height: ${(p) => p.theme.lineHeight.sm};
  margin-right: ${(p) => p.theme.spacing.lg};
  cursor: pointer;
  &:last-child {
    margin-right: 0;
  }
  &:hover {
    color: ${(p) => p.theme.palette.layout.depth5};
  }
`;
