import { Icon as RawIcon } from "../Icon";
import styled, { keyframes } from "styled-components";
import { SpinnerProps } from "./Spinner.types";

const spin = keyframes`
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
`;

export const Spinner = styled.div<SpinnerProps>`
  width: ${(p) => (p.height ? `${p.height}rem` : "2.8125rem")};
  height: ${(p) => (p.height ? `${p.height}rem` : "2.8125rem")};
  animation-play-state: ${(p) => (p.isPausable ? "paused" : "running")};
`;

export const Icon = styled(RawIcon)<SpinnerProps>`
  height: ${(p) => (p.height ? `${p.height}rem` : "2.8125rem")};
  width: ${(p) => (p.height ? `${p.height}rem` : "2.8125rem")};
  color: ${(p) => p.color || p.theme.palette.primary.main};
  animation: ${spin} 0.7s linear infinite;
`;
