import styled from "styled-components";
import { PaginationButtonProps } from "./Pagination.types";
import { Icon } from "../Icon";

export const PaginationWrapper = styled.div`
  display: flex;
  align-items: center;
  color: ${(p) => p.theme.palette.layout.depth6};
  font-size: ${(p) => p.theme.fontSize.xs};
`;

export const PaginationButton = styled.button<PaginationButtonProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 1.8rem;
  height: 1.8rem;
  font-size: ${(p) => p.theme.fontSize.xs};
  margin-right: ${(p) => p.theme.spacing.xs2};
  border-radius: ${(p) => p.theme.radius.radius2};
  border-color: transparent;
  background-color: ${(p) => (p.isSelected ? p.theme.palette.primary.main : "transparent")};
  color: ${(p) => (p.isSelected ? p.theme.palette.primary.contrastText : p.theme.palette.layout.depth6)};

  &:hover {
    cursor: ${(p) => p.onClick && "pointer"};
    background-color: ${(p) => !p.isSelected && p.onClick && p.theme.palette.primary.opacity2};
  }

  &:disabled {
    cursor: not-allowed;
    background-color: transparent;
    opacity: ${(p) => p.theme.opacity.opacity2};
  }
`;

export const PaginationIcon = styled(Icon)``;
