import styled, { css, DefaultTheme } from "styled-components";
import { simpleRotation } from "../../utils/animations";
import { Icon as RawIcon } from "../Icon";
import {
  DropdownContentProps,
  DropdownOptionProps,
  DropdownPlaceholderProps,
  DropdownProps,
} from "./Dropdown.types";

const getPadding = (t: any) => `${t.spacing.xs} ${t.spacing.sm}`;

export const DropdownContainer = styled.div`
  position: relative;
  color: ${(p) => p.theme.palette.layout.depth6};
  display: flex;
  flex-direction: column;
  width: 14.875rem;
  height: 100%;
`;

export const Dropdown = styled.div<DropdownProps>`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  height: ${(p) => (p.size === "Large" ? "2.5rem" : "2rem")};
  width: 100%;
  box-sizing: border-box;

  background-color: ${(p) => (p.variant === "Dropdown" ? p.theme.palette.layout.depth3 : "transparent")};
  border-radius: ${(p) => p.theme.radius.radius2};
  border-bottom-left-radius: ${(p) => p.theme.radius.radius2};
  border-bottom-right-radius: ${(p) => p.theme.radius.radius2};
  cursor: pointer;
  overflow: hidden;
  padding: ${(p) => `${p.theme.spacing.xs3} ${p.theme.spacing.sm}`};

  &:hover {
    background-color: ${(p) => p.theme.palette.primary.opacity1};
  }
`;

export const DropdownImageWrapper = styled.div`
  display: flex;
  justify-content: center;
  max-height: 2.625rem;

  padding: ${(p) => `${p.theme.spacing.xs3} ${p.theme.spacing.sm}`};
  img {
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;
  }
`;

export const DropdownPlaceholderWrapper = styled.div<DropdownPlaceholderProps>`
  display: flex;
  align-items: center;
  justify-content: end;
`;

export const DropdownPlaceholder = styled.div`
  margin-right: ${(p) => p.theme.spacing.sm};
`;

export const DropdownContent = styled.div<DropdownContentProps>`
  position: relative;
  top: ${(p) => p.theme.spacing.xs3};
  display: ${(p) => (p.open ? "flex" : "none")};
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  background-color: ${(p) => p.theme.palette.layout.depth3};
  border-radius: ${(p) => p.theme.radius.radius2};
  border-top-left-radius: ${(p) => p.theme.radius.radius2};
  border-top-right-radius: ${(p) => p.theme.radius.radius2};

  &:first-child {
    border-top-left-radius: ${(p) => p.theme.radius.radius2};
    border-top-right-radius: ${(p) => p.theme.radius.radius2};
  }
  &:last-child {
    border-bottom-right-radius: ${(p) => p.theme.radius.radius2};
    border-bottom-left-radius: ${(p) => p.theme.radius.radius2};
  }

  input {
    font-size: ${(p) => p.theme.fontSize.md};
    line-height: ${(p) => p.theme.lineHeight.sm};
    color: ${(p) => p.theme.palette.layout.depth6};
  }
`;

export const DropdownIcon = styled(RawIcon)`
  height: 1rem;
  width: 1rem;
  color: ${(p) => p.theme.palette.layout.depth6};
  padding-right: ${(p) => p.theme.spacing.xs};
`;

export const Icon = styled(RawIcon)<{ $isOpen: boolean }>`
  height: 1rem;
  width: 1rem;

  ${({ $isOpen }) =>
    $isOpen &&
    css`
      ${simpleRotation(180)};
      transform: rotate(180deg);
    `}
`;

export const DropdownLabel = styled.label`
  font-size: ${(p) => p.theme.fontSize.md};
  line-height: ${(p) => p.theme.lineHeight.sm};
  color: ${(p) => p.theme.palette.layout.depth6};
  padding-bottom: ${(p) => p.theme.spacing.xs};
`;

export const DropdownOption = styled.button<DropdownOptionProps>`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  background-color: transparent;
  border: none;
  line-height: ${(p) => p.theme.lineHeight.sm};
  padding: ${(p) => getPadding(p.theme)};
  cursor: pointer;
  font-size: ${(p) => p.theme.fontSize.md};
  line-height: ${(p) => p.theme.lineHeight.sm};
  color: ${(p) => p.theme.palette.layout.depth6};
  padding: ${(p) => getPadding(p.theme)};

  &:hover {
    background-color: ${(p) => p.theme.palette.primary.opacity1};
  }
`;
