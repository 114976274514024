import { Icon as RawIcon } from "../Icon";
import styled, { DefaultTheme } from "styled-components";
import { ButtonBottomLabelProps, ButtonContainerProps, ButtonProps } from "./Button.types";
import { findPalette } from "../../utils/styleUtils";

type ButtonPropsWithTheme = ButtonProps & { theme: DefaultTheme };

export const Container = styled.div<ButtonContainerProps>`
  flex-direction: column;
  display: flex;
  align-items: center;
  justify-content: ${(p) => (p.label ? "center" : "flex-start")};
  width: ${(p) => (p.fullWidth ? "100%" : "fit-content")};
`;

export const Button = styled.button<ButtonProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: ${(p) => p.theme.fontSize.xs};
  font-weight: ${(p) => p.theme.fontWeight.bold};
  height: ${(p) => (p.size === "Small" ? "2rem" : "2.5rem")};
  width: ${(p) => getWidth(p)};
  padding: ${(p) =>
    p.size === "Small" ? `${p.theme.spacing.sm} ${p.theme.spacing.md}` : `0px ${p.theme.spacing.md}`};
  ${(p) => p.kind !== "Circular" && p.fullWidth && "width: 100%"};

  border-radius: ${(p) => (p.kind === "Circular" ? `${p.theme.radius.radius6}` : `${p.theme.spacing.xs}`)};
  border: ${(p) => (p.kind === "Outline" ? `1px solid ${p.theme.palette.primary.main}` : "none")};
  background-color: ${(p) => getBackground(p)};
  color: ${(p) => getVariant(p).contrastText};

  cursor: pointer;
  &:not([disabled]):hover {
    background-color: ${(p) => getHoverBackground(p)};
    color: ${(p) => getVariant(p).contrastText};
  }
  &:not([disabled]):active {
    background-color: ${(p) => getActiveBackground(p)};
    color: ${(p) => getVariant(p).contrastText};
  }
  &[disabled] {
    cursor: not-allowed;
    opacity: ${(p) => p.theme.opacity.opacity2};
  }

  -webkit-transition: background-color 200ms ease-out;
  -moz-transition: background-color 200ms ease-out;
  -o-transition: background-color 200ms ease-out;
  transition: background-color 200ms ease-out;
`;

export const Icon = styled(RawIcon)`
  height: 1rem;
  width: 1rem;
`;

export const BottomLabel = styled.span<ButtonBottomLabelProps>`
  padding-top: ${(p) => p.theme.spacing.xs2};
  font-size: ${(p) => p.theme.fontSize.xs2};
  line-height: ${(p) => p.theme.lineHeight.xs2};
  font-weight: ${(p) => p.theme.fontWeight.bold};
  color: ${(p) => p.theme.palette.lightning.light};
  opacity: ${(p) => p.disabled && p.theme.opacity.opacity2};
`;

const getWidth = (p: ButtonProps) => {
  if (p.kind !== "Circular") return "auto";
  switch (p.size) {
    case "Large":
      return "2.5rem";
    case "Small":
      return "2rem";
  }
};

const getVariant = (p: ButtonPropsWithTheme) => {
  if (p.kind === "Ghost" || p.kind === "Outline") return p.theme.palette.secondary;
  return findPalette(p.theme, p.variant?.toLowerCase() || "primary");
};

const getBackground = (p: ButtonPropsWithTheme) => {
  const palette = findPalette(p.theme, p.variant?.toLowerCase() || "primary");
  switch (p.kind) {
    case "Solid":
    case "Circular":
      return palette.main;
    case "Outline":
    case "Ghost":
      return "transparent";
  }
};

const getHoverBackground = (p: ButtonPropsWithTheme) => {
  const palette = findPalette(p.theme, p.variant?.toLowerCase() || "primary");
  if (p.variant === "Secondary") return palette.opacity2;
  switch (p.kind) {
    case "Solid":
    case "Circular":
      return palette.dark1;
    case "Outline":
    case "Ghost":
      return p.theme.palette.secondary.opacity1;
  }
};

const getActiveBackground = (p: ButtonPropsWithTheme) => {
  const v = p.variant?.toLowerCase();
  if (!v) return p.theme.palette.primary.dark2;
  const palette = findPalette(p.theme, v);
  if (v === "secondary") return palette.opacity3;
  switch (p.kind) {
    case "Solid":
    case "Circular":
      return palette.dark2;
    case "Outline":
    case "Ghost":
      return p.theme.palette.secondary.opacity2;
  }
};
