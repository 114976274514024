import styled, { css, DefaultTheme } from "styled-components";
import { simpleRotation } from "../../utils/animations";
import { Icon } from "../Icon";
import { AccordionTitleProps, AccordionBodyProps } from "./Accordion.types";

const getPadding = (t: DefaultTheme) => `${t.spacing.xs} ${t.spacing.sm}`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  color: ${(p) => p.theme.palette.layout.depth6};
`;

export const AccordionItem = styled.div`
  margin-bottom: 1px;

  &:first-child {
    border-top-left-radius: ${(p) => p.theme.radius.radius2};
    border-top-right-radius: ${(p) => p.theme.radius.radius2};
  }

  &:last-child {
    margin-bottom: 0;
    border-bottom-left-radius: ${(p) => p.theme.radius.radius2};
    border-bottom-right-radius: ${(p) => p.theme.radius.radius2};
  }
`;

export const AccordionTitle = styled.h1<AccordionTitleProps>`
  padding: ${(p) => getPadding(p.theme)};
  margin-block-start: 0;
  margin-block-end: 0;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-weight: ${(p) => p.theme.fontWeight.bold};
  font-size: ${(p) => p.theme.fontSize.xs};
  line-height: ${(p) => p.theme.lineHeight.xs};
  height: 100%;
  background-color: ${(p) => p.theme.palette.layout.depth3};
  border-top-left-radius: ${(p) => p.first && p.theme.radius.radius2};
  border-top-right-radius: ${(p) => p.first && p.theme.radius.radius2};
  border-bottom-left-radius: ${(p) => p.last && !p.active && p.theme.radius.radius2};
  border-bottom-right-radius: ${(p) => p.last && !p.active && p.theme.radius.radius2};

  &:hover {
    background-color: ${(p) => p.theme.palette.primary.opacity1};
  }
`;

export const AccordionBody = styled.div<AccordionBodyProps>`
  padding-top: 0;
  padding-bottom: 0;
  padding-left: ${(p) => p.theme.spacing.sm};
  padding-right: ${(p) => p.theme.spacing.sm};

  margin-block-start: 0;
  margin-block-end: 0;
  margin-top: 1px;
  font-weight: ${(p) => p.theme.fontWeight.regular};
  font-size: ${(p) => p.theme.fontSize.sm};
  display: none;
  height: 0px;
  opacity: 0;

  background-color: transparent;
  border-bottom-left-radius: ${(p) => p.last && p.theme.radius.radius2};
  border-bottom-right-radius: ${(p) => p.last && p.theme.radius.radius2};

  transition-property: all;
  transition-duration: 200ms;
  transition-timing-function: ease-in;

  ${(p) =>
    p.active &&
    `
		opacity:1;
		padding-top: ${p.theme.spacing.xs};
		padding-bottom: ${p.theme.spacing.xs};
    display: block;
		height: auto;
		background-color: ${p.theme.palette.layout.depth3};	
		transition-property:all;
		transition-duration: 200ms;
		transition-timing-function: ease-in;
`}
`;

export const AccordionIcon = styled(Icon)<{ $active: boolean }>`
  width: 1.333rem;
  color: ${(p) => p.theme.palette.layout.depth6};

  ${({ $active }) =>
    $active &&
    css`
      ${simpleRotation(180)};
      transform: rotate(180deg);
    `}
`;
