import React from "react";
import { StyledRadioButton, StyledContainer, StyledLabel } from "./Radio.style";
import { RadioType } from "./Radio.types";

export const Radio = (props: RadioType) => {
  const { checked, disabled, onChange, id, value, readOnly } = props;

  return (
    <StyledContainer disabled={disabled}>
      <StyledRadioButton
        type={"radio"}
        name={id}
        id={id}
        checked={checked}
        disabled={disabled || readOnly}
        onChange={onChange}
        value={value}
        readOnly={readOnly}
      />
      <StyledLabel htmlFor={id} disabled={disabled || readOnly}>
        {props.label}
      </StyledLabel>
    </StyledContainer>
  );
};
