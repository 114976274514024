import styled from "styled-components";
import { CardProps } from "./Card.types";

export const Card = styled.div<CardProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  width: ${(p) => (p.width ? p.width : "100%")};
  height: ${(p) => (p.height ? p.height : "100%")};
  border-radius: ${(p) => p.theme.radius.radius2};
  background-color: ${(p) =>
    p.variant === "Light" ? p.theme.palette.layout.depth7 : p.theme.palette.layout.depth2};
`;
