import styled from "styled-components";
import { SliderContainerProps } from "./Slider.types";

export const StyledContainer = styled.div`
  width: fit-content;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const StyledSlider = styled.input<SliderContainerProps>`
  appearance: none;
  width: 230px;
  height: 5px;
  border-radius: ${(p) => p.theme.radius.radius1};
  background: -webkit-linear-gradient(
    left,
    ${(p) => p.theme.palette.primary.main} ${(p) => (p.value / p.max) * 100}%,
    ${(p) => p.theme.palette.primary.main} ${(p) => (p.value / p.max) * 100}%,
    #eceeef ${(p) => (p.value / p.max) * 100}%
  ); /* There is no color from the pallet yet */
  outline: none;
  -webkit-transition: 0.2s;
  transition: opacity 0.2s;
  &:hover {
    opacity: ${(p) => p.theme.palette.primary.opacity1};
  }
  &::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: ${(p) => p.theme.fontSize.sm};
    height: ${(p) => p.theme.fontSize.sm};
    border-radius: 50%;
    background: ${(p) => p.theme.palette.primary.main};
    cursor: pointer;
  }
`;

export const StyledLabel = styled.span`
font-size: ${(p) => p.theme.fontSize.sm};
  margin-left: ${(p) => p.theme.spacing.xs};
  color: ${(p) => p.theme.palette.info.contrastText};
`;
