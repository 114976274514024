import styled, { css, DefaultTheme } from "styled-components";
import {
  AddonProps,
  InputBottomLabelProps,
  InputContainerProps,
  InputProps,
  InputSize,
  InputWrapperProps,
  LabelProps,
} from "./Input.types";
import { Icon as RawIcon } from "../Icon";

const getBorderRadius = (t: DefaultTheme) => t.radius.radius2;

const removeNumberInputStyle = css`
  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  ::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`;

export const TopLabels = styled.div`
  display: flex;
  justify-content: space-between;
  font-size: ${(p) => p.theme.fontSize.sm};
`;

export const Label = styled.label<LabelProps>`
  display: flex;
  margin-bottom: ${(p) => !p.labelOnLeft && !p.labelOnRight && p.theme.spacing.sm};
  margin-left: ${(p) => p.labelOnRight && p.theme.spacing.sm};
  font-size: ${(p) => p.theme.fontSize.sm};
`;

export const Required = styled.div`
  width: 0.5rem;
  color: ${(p) => p.theme.palette.danger.main};
  margin-left: 4px;
`;

export const CornerHint = styled.span`
  line-height: ${(p) => p.theme.lineHeight.sm};
  font-size: ${(p) => p.theme.fontSize.sm};
  color: ${(p) => p.theme.palette.layout.depth5};
  width: 100%;
  text-align: right;
  position: absolute;
  top: -2.05rem;
`;

export const BottomLabel = styled.span<InputBottomLabelProps>`
  position: absolute;
  margin-top: ${(p) => p.theme.spacing.xs};
  font-size: ${(p) => p.theme.fontSize.xs};
  font-weight: 400;
  line-height: ${(p) => p.theme.lineHeight.xs};
  color: ${(p) => (p.isError ? p.theme.palette.danger.main : p.theme.palette.layout.depth5)};
`;

export const Addon = styled.div<AddonProps>`
  height: 100%;
  display: flex;
  align-items: center;
  border-top-right-radius: ${(p) => getBorderRadius(p.theme)};
  border-bottom-right-radius: ${(p) => getBorderRadius(p.theme)};
  background-color: ${(p) => p.theme.palette.layout.depth4};
  font-size: ${(p) => p.theme.fontSize.sm};
  padding: 0 ${(p) => p.theme.spacing.sm};
  line-height: ${(p) => p.theme.lineHeight.sm};
  border: ${(p) => (p.isError ? "none" : `1px solid ${p.theme.palette.layout.depth4}`)};
`;

export const InputWrapper = styled.div<InputWrapperProps>`
  display: flex;
  align-items: center;
  width: 100%;
  height: ${(p) => (p.size === "Small" ? "2rem" : "2.5rem")};
  box-shadow: ${(p) => p.isInFocus && `0px 0px 4px ${p.theme.palette.primary.main}`};
  border-radius: ${(p) => p.theme.radius.radius2};
  border: 1px solid ${(p) => (p.isError ? p.theme.palette.danger.main : p.theme.palette.layout.depth3)};
  background-color: ${(p) => p.theme.palette.layout.depth3};
`;

export const ExtraInputWrapper = styled.div<{ size: InputSize; iconOnLeft?: boolean }>`
  display: flex;
  align-items: center;
  width: 100%;
  height: ${(p) => (p.size === "Small" ? "2rem" : "2.5rem")};
  flex-direction: ${(p) => (p.iconOnLeft ? "unset" : "row-reverse")};
`;

export const IconWrapper = styled.div<{ iconOnLeft?: boolean }>`
  display: flex;
  align-items: center;
  height: 95%;
  padding: 0 ${(p) => (p.iconOnLeft ? p.theme.spacing.xs : p.theme.spacing.xs)} 0
    ${(p) => (p.iconOnLeft ? p.theme.spacing.sm : p.theme.spacing.xs)};
  line-height: ${(p) => p.theme.lineHeight.sm};
  border-radius: ${(p) => `${getBorderRadius(p.theme)} 0 0 ${getBorderRadius(p.theme)}`};

  svg {
    path {
      fill: ${(p) => p.theme.palette.layout.depth5};
    }
  }
`;

export const Icon = styled(RawIcon)<{ height?: string }>`
  height: ${(p) => (p.height ? p.height : "100%")};
  path {
    fill: ${(p) => p.theme.palette.layout.depth5};
  }
`;

export const InputContent = styled.div`
  position: relative;
`;

export const Input = styled.input<InputProps>`
  background-color: transparent;
  border-radius: ${(p) => p.theme.radius.radius2};
  border: none;
  color: ${(p) => p.theme.palette.layout.depth6};
  line-height: ${(p) => p.theme.lineHeight.sm};
  font-size: ${(p) => p.theme.fontSize.sm};
  font-weight: ${(p) => p.theme.fontWeight.regular};
  padding: ${(p) =>
    p.labelOnLeft ? `0 ${p.theme.spacing.sm} 0 0` : `0 ${p.theme.spacing.sm} 0 ${p.theme.spacing.sm}`};
  height: 100%;
  width: 100%;
  &:focus-visible {
    outline: none;
    background-color: ${(p) => p.theme.palette.layout.depth3};
  }
  &:focus {
    outline: none;
    background-color: ${(p) => p.theme.palette.layout.depth3};
  }
  &:disabled {
    opacity: ${(p) => !p.readOnly && p.theme.opacity.opacity2};
    cursor: not-allowed;
  }
  &:hover {
    border-color: ${(p) => !p.disabled && p.theme.palette.primary.opacity1};
  }
  &::placeholder {
    color: ${(p) => p.theme.palette.layout.depth5};
  }
  ${removeNumberInputStyle};
`;

export const Container = styled.div<InputContainerProps>`
  position: relative;
  display: flex;
  align-items: ${(p) => (p.labelOnLeft || p.labelOnRight ? "center" : "left")};
  flex-direction: ${(p) => (p.labelOnRight ? "row-reverse" : p.labelOnLeft ? "row" : "column")};
  width: ${(p) => p.width || (p.flex ? "100%" : "fit-content")};
  flex: ${(p) => p.flex};
  margin-top: ${(p) => (p.cornerHint && (p.labelOnLeft || !p.label) ? "2.05rem" : 0)};
  margin-bottom: ${(p) => p.isError && p.theme.spacing.xl2};
  color: ${(p) => p.theme.palette.layout.depth6};
  > * {
    flex: ${(p) => p.labelOnLeft && 1};
    min-width: ${(p) => p.labelOnLeft && "12.5rem"};
  }
  ${TopLabels}:not(:empty) + ${InputWrapper} {
    margin-top: ${(p) => p.theme.spacing.sm};
  }
`;

export const SpinnerContainer = styled.div`
  position: absolute;
  right: 0.5rem;
`;
