import { Icon } from "../Icon";
import styled, { css } from "styled-components";
import { NavbarLinkProps } from "./NavbarLink.types";
import { simpleRotation, simpleTransition } from "../../utils/animations";

export const activeClassName = "active";

export const NavbarLink = styled.div<NavbarLinkProps>`
  cursor: pointer;
  position: relative;
  width: 100%;
  display: inline-block;
  text-decoration: none;
  padding: ${(p) => p.theme.spacing.sm} 0;
  margin: 0;
  margin-bottom: ${(p) => p.theme.spacing.sm};
  &:hover {
    background-color: ${(p) => p.theme.palette.primary.opacity2};
  }

  &::after {
    display: block;
    content: "";
    position: absolute;
    bottom: 0;
    border-bottom: ${(p) => p.theme.spacing.xs3} solid ${(p) => p.theme.palette.primary.main};
    transform: scaleX(0);
    transition: transform 250ms ease-in-out;
  }
  &.${activeClassName}::after {
    transform: scaleX(1);
    left: 0;
    right: 0;
  }
`;

export const NavbarIcon = styled(Icon)`
  position: relative;
  left: calc(var(--navbar-width-collapsed) / 2 - 0.5rem);
  color: ${(p) => p.theme.palette.layout.depth6};
  width: auto;
  height: 1rem;
`;

export const ChildIcon = styled(Icon)<{ $isOpen: boolean }>`
  left: calc(var(--navbar-width-collapsed) / 2 - 0.5rem);
  color: ${(p) => p.theme.palette.layout.depth6};
  width: 1rem;
  height: 1rem;
  transition: transform 200ms ease-in-out;

  ${({ $isOpen }) =>
    $isOpen &&
    css`
      ${simpleRotation(180)};
      transform: rotate(180deg);
    `}
`;

export const LinkText = styled.span<{ isOpen: boolean }>`
  display: flex;
  justify-content: space-between;
  width: 100%;
  font-size: ${(p) => p.theme.fontSize.xs};
  line-height: ${(p) => p.theme.lineHeight.xs2};
  margin: 0 ${(p) => p.theme.spacing.sm} 0;
  color: ${(p) => p.theme.palette.layout.depth6};
  transition: opacity 400ms ease-in;
  opacity: 0;
  transform: none;
  overflow: hidden;

  --navbar:hover & {
    transition: opacity 200ms ease-in;
    opacity: 1;
  }

  ${({ isOpen }) =>
    isOpen &&
    `
    opacity: 1;
    transition: opacity 200ms ease-in;
  `};
`;

export const IconTextWrapper = styled.span`
  display: flex;
  gap: ${(p) => p.theme.spacing.xl};
  flex-direction: row;
  align-items: center;
  white-space: nowrap;
`;

export const NavbarChildWrapper = styled.div<{ $isOpen: boolean }>`
  display: flex;
  flex-direction: column;

  ${simpleTransition("opacity", 400)}
  height: 0;
  opacity: 0;
  transform: none;

  ${NavbarIcon} {
    color: transparent;
  }

  ${({ $isOpen }) =>
    $isOpen &&
    css`
      height: auto;
      opacity: 1;
      ${simpleTransition("height opacity")}
    `};
`;
