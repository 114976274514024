import React, { forwardRef, useState } from "react";
import * as Styled from "./TextArea.style";
import { TextAreaProps } from "./TextArea.types";

export const TextArea = forwardRef<HTMLTextAreaElement, TextAreaProps>((props, ref) => {
  const {
    label,
    disabled = false,
    textareaOnChange,
    placeholder,
    error,
    cornerHint,
    helpText,
    required,
    className,
    readOnly,
    defaultValue,
  } = props;
  const [value, setValue] = useState(props.value);

  const handleOnChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    event.preventDefault();
    if (!disabled) {
      if (textareaOnChange !== undefined) {
        setValue(event.target.value);
        textareaOnChange(event.target.value);
      } else {
        undefined;
      }
    }
  };

  return (
    <Styled.Container className={className}>
      <Styled.LabelWrapper $onlyCornerHint={label ? false : true}>
        {label && (
          <Styled.Label>
            {label}
            {required && <Styled.Required>*</Styled.Required>}
          </Styled.Label>
        )}
        {cornerHint && <Styled.CornerHint>{cornerHint}</Styled.CornerHint>}
      </Styled.LabelWrapper>
      <Styled.TextArea
        ref={ref}
        placeholder={placeholder}
        required={required}
        disabled={disabled || readOnly}
        value={value}
        readOnly={readOnly}
        onChange={handleOnChange}
        defaultValue={defaultValue}
        {...props}
      />
      {error ? (
        <Styled.BottomLabel isError>{error}</Styled.BottomLabel>
      ) : (
        <Styled.BottomLabel isError={false}> {helpText}</Styled.BottomLabel>
      )}
    </Styled.Container>
  );
});

TextArea.displayName = "TextArea";
