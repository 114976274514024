import React from "react";

export default function MarkerSolid(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="24" height="28" viewBox="0 0 24 28" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M11.8181 0C5.29083 0 0 5.31952 0 11.8019C0 15.0433 1.32322 17.9693 3.46189 20.1667H3.46163L11.8182 28L20.2522 19.9898L20.2535 19.9745C22.346 17.8568 23.6364 14.9465 23.6364 11.7456C23.6366 5.26335 18.3453 0.000631619 11.8182 0.000631619L11.8181 0Z"
        fill="#FFD4A3"
      />
    </svg>
  );
}
