import styled from "styled-components";

export const MarkerContainer = styled.div``;

export const MarkerWrapper = styled.button<{ lat: number; lng: number; direction: number; zIndex?: number }>`
  width: 55px;
  height: 55px;
  padding: 0;
  margin: 0;
  transform: translate(-50%, -50%);
  border: none;
  background-color: transparent;
  position: relative;
  ${MarkerContainer} {
    width: 55px;
    height: 55px;
    transform: ${(p) => `rotate(${p.direction || 0}deg)`};
  }
`;

export const MarkerTitle = styled.span`
  display: none;
  white-space: nowrap;
  padding: ${(p) => p.theme.spacing.xs};
  margin-bottom: ${(p) => p.theme.spacing.xs3};
  font-size: ${(p) => p.theme.fontSize.xs};
  background-color: ${(p) => p.theme.palette.layout.depth6};
  color: ${(p) => p.theme.palette.layout.depth1};
  width: fit-content;

  position: absolute;
  bottom: 100%;
  left: -50%;
  ${MarkerWrapper}:hover & {
    display: block;
  }
`;
