import React, { forwardRef, useState } from "react";
import { RadioGroupProps } from "./RadioGroup.type";
import * as Styled from "./RadioGroup.style";

export const RadioGroup = forwardRef<HTMLInputElement, RadioGroupProps>((props: RadioGroupProps, ref) => {
  const {
    className,
    initialSelectedId,
    onChange,
    children,
    title,
    disabled = false,
    required = false,
    readOnly = false,
    error,
  } = props;
  const [selectedRadio, setSelectedRadio] = useState(initialSelectedId);

  const injectProps = (child: React.ReactElement) => {
    const { id, value } = child.props;
    const propsToInject = {
      key: id,
      checked: id === selectedRadio,
      value,
      disabled,
      readOnly,
      onChange: (event: React.ChangeEvent<HTMLInputElement>) => {
        setSelectedRadio(id);
        onChange && onChange(event);
      },
    };
    return React.cloneElement(child, propsToInject);
  };

  const childrenWithProps = React.Children.map(children, injectProps);
  return (
    <Styled.RadioGroupWrapper className={className}>
      <Styled.RadioGroupLabel>
        {title}
        {required && <Styled.Required>*</Styled.Required>}
      </Styled.RadioGroupLabel>
      <Styled.RadioGroup>{childrenWithProps}</Styled.RadioGroup>
      <Styled.BottomRadioGroupLabel>{error}</Styled.BottomRadioGroupLabel>
    </Styled.RadioGroupWrapper>
  );
});

RadioGroup.displayName = "RadioGroup";
