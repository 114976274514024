import React from "react";

export default function SpinnerShield(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      preserveAspectRatio="xMidYMid meet"
      width="80"
      height="80"
      viewBox="0 0 80 80"
      {...props}>
      <defs>
        <animateTransform
          repeatCount="indefinite"
          dur="3.8833333s"
          begin="0s"
          xlinkHref="#_R_G_L_19_G"
          fill="freeze"
          attributeName="transform"
          from="0 0"
          to="1 1"
          type="scale"
          additive="sum"
          keyTimes="0;0.0085837;0.1536481;0.2451502;0.3433476;1"
          values="0 0;0 0;1 1;0.9 0.9;1 1;1 1"
          keySplines="0.167 0.167 0.48 1;0.167 0.167 0.48 1;0.52 0 0.48 1;0.52 0 0.48 1;0 0 0 0"
          calcMode="spline"
        />
        <animate
          repeatCount="indefinite"
          dur="3.8833333s"
          begin="0s"
          xlinkHref="#_R_G_L_19_G_M"
          fill="freeze"
          attributeName="opacity"
          from="0"
          to="0"
          keyTimes="0;0.0085837;0.0085841;0.4291845;0.4291846;1"
          values="0;0;1;1;0;0"
          keySplines="0 0 0 0;0 0 0 0;0 0 0 0;0 0 0 0;0 0 0 0"
          calcMode="spline"
        />
        <animateTransform
          repeatCount="indefinite"
          dur="3.8833333s"
          begin="0s"
          xlinkHref="#_R_G_L_18_G"
          fill="freeze"
          attributeName="transform"
          from="0 0"
          to="1 1"
          type="scale"
          additive="sum"
          keyTimes="0;0.0171674;0.1622318;0.2537339;0.3519313;1"
          values="0 0;0 0;1 1;0.9 0.9;1 1;1 1"
          keySplines="0.167 0.167 0.48 1;0.167 0.167 0.48 1;0.52 0 0.48 1;0.52 0 0.48 1;0 0 0 0"
          calcMode="spline"
        />
        <animate
          repeatCount="indefinite"
          dur="3.8833333s"
          begin="0s"
          xlinkHref="#_R_G_L_18_G_M"
          fill="freeze"
          attributeName="opacity"
          from="0"
          to="0"
          keyTimes="0;0.0171674;0.0171678;0.4291845;0.4291846;1"
          values="0;0;1;1;0;0"
          keySplines="0 0 0 0;0 0 0 0;0 0 0 0;0 0 0 0;0 0 0 0"
          calcMode="spline"
        />
        <animateTransform
          repeatCount="indefinite"
          dur="3.8833333s"
          begin="0s"
          xlinkHref="#_R_G_L_17_G"
          fill="freeze"
          attributeName="transform"
          from="0 0"
          to="1 1"
          type="scale"
          additive="sum"
          keyTimes="0;0.0257511;0.1708155;0.2618026;0.360515;1"
          values="0 0;0 0;1 1;0.7000000000000001 0.7000000000000001;1 1;1 1"
          keySplines="0.167 0.167 0.48 1;0.167 0.167 0.48 1;0.52 0 0.48 1;0.52 0 0.48 1;0 0 0 0"
          calcMode="spline"
        />
        <animate
          repeatCount="indefinite"
          dur="3.8833333s"
          begin="0s"
          xlinkHref="#_R_G_L_17_G_M"
          fill="freeze"
          attributeName="opacity"
          from="0"
          to="0"
          keyTimes="0;0.0257511;0.0257515;0.4291845;0.4291846;1"
          values="0;0;1;1;0;0"
          keySplines="0 0 0 0;0 0 0 0;0 0 0 0;0 0 0 0;0 0 0 0"
          calcMode="spline"
        />
        <animateTransform
          repeatCount="indefinite"
          dur="3.8833333s"
          begin="0s"
          xlinkHref="#_R_G_L_16_G"
          fill="freeze"
          attributeName="transform"
          from="0 0"
          to="1 1"
          type="scale"
          additive="sum"
          keyTimes="0;0.0343348;0.1793991;0.2709013;0.3690987;1"
          values="0 0;0 0;1 1;0.7000000000000001 0.7000000000000001;1 1;1 1"
          keySplines="0.167 0.167 0.48 1;0.167 0.167 0.48 1;0.52 0 0.48 1;0.52 0 0.48 1;0 0 0 0"
          calcMode="spline"
        />
        <animate
          repeatCount="indefinite"
          dur="3.8833333s"
          begin="0s"
          xlinkHref="#_R_G_L_16_G_M"
          fill="freeze"
          attributeName="opacity"
          from="0"
          to="0"
          keyTimes="0;0.0343348;0.0343352;0.4291845;0.4291846;1"
          values="0;0;1;1;0;0"
          keySplines="0 0 0 0;0 0 0 0;0 0 0 0;0 0 0 0;0 0 0 0"
          calcMode="spline"
        />
        <animateTransform
          repeatCount="indefinite"
          dur="3.8833333s"
          begin="0s"
          xlinkHref="#_R_G_L_15_G"
          fill="freeze"
          attributeName="transform"
          from="0 0"
          to="1 1"
          type="scale"
          additive="sum"
          keyTimes="0;0.0429185;0.1879828;0.279485;0.3776824;1"
          values="0 0;0 0;1 1;0.7000000000000001 0.7000000000000001;1 1;1 1"
          keySplines="0.167 0.167 0.48 1;0.167 0.167 0.48 1;0.52 0 0.48 1;0.52 0 0.48 1;0 0 0 0"
          calcMode="spline"
        />
        <animate
          repeatCount="indefinite"
          dur="3.8833333s"
          begin="0s"
          xlinkHref="#_R_G_L_15_G_M"
          fill="freeze"
          attributeName="opacity"
          from="0"
          to="0"
          keyTimes="0;0.0429185;0.0429189;0.4291845;0.4291846;1"
          values="0;0;1;1;0;0"
          keySplines="0 0 0 0;0 0 0 0;0 0 0 0;0 0 0 0;0 0 0 0"
          calcMode="spline"
        />
        <animateTransform
          repeatCount="indefinite"
          dur="3.8833333s"
          begin="0s"
          xlinkHref="#_R_G_L_14_G"
          fill="freeze"
          attributeName="transform"
          from="0 0"
          to="1 1"
          type="scale"
          additive="sum"
          keyTimes="0;0.0515021;0.1965665;0.2880687;0.3862661;1"
          values="0 0;0 0;1 1;0.7000000000000001 0.7000000000000001;1 1;1 1"
          keySplines="0.167 0.167 0.48 1;0.167 0.167 0.48 1;0.52 0 0.48 1;0.52 0 0.48 1;0 0 0 0"
          calcMode="spline"
        />
        <animate
          repeatCount="indefinite"
          dur="3.8833333s"
          begin="0s"
          xlinkHref="#_R_G_L_14_G_M"
          fill="freeze"
          attributeName="opacity"
          from="0"
          to="0"
          keyTimes="0;0.0515021;0.0515026;0.4291845;0.4291846;1"
          values="0;0;1;1;0;0"
          keySplines="0 0 0 0;0 0 0 0;0 0 0 0;0 0 0 0;0 0 0 0"
          calcMode="spline"
        />
        <animateTransform
          repeatCount="indefinite"
          dur="3.8833333s"
          begin="0s"
          xlinkHref="#_R_G_L_13_G"
          fill="freeze"
          attributeName="transform"
          from="0 0"
          to="1 1"
          type="scale"
          additive="sum"
          keyTimes="0;0.0600858;0.2051502;0.2966524;0.3948498;1"
          values="0 0;0 0;1 1;0.7000000000000001 0.7000000000000001;1 1;1 1"
          keySplines="0.167 0.167 0.48 1;0.167 0.167 0.48 1;0.52 0 0.48 1;0.52 0 0.48 1;0 0 0 0"
          calcMode="spline"
        />
        <animate
          repeatCount="indefinite"
          dur="3.8833333s"
          begin="0s"
          xlinkHref="#_R_G_L_13_G_M"
          fill="freeze"
          attributeName="opacity"
          from="0"
          to="0"
          keyTimes="0;0.0600858;0.0600863;0.4291845;0.4291846;1"
          values="0;0;1;1;0;0"
          keySplines="0 0 0 0;0 0 0 0;0 0 0 0;0 0 0 0;0 0 0 0"
          calcMode="spline"
        />
        <animateTransform
          repeatCount="indefinite"
          dur="3.8833333s"
          begin="0s"
          xlinkHref="#_R_G_L_12_G"
          fill="freeze"
          attributeName="transform"
          from="0 0"
          to="1 1"
          type="scale"
          additive="sum"
          keyTimes="0;0.0686695;0.2137339;0.3052361;0.4034335;1"
          values="0 0;0 0;1 1;0.7000000000000001 0.7000000000000001;1 1;1 1"
          keySplines="0.167 0.167 0.48 1;0.167 0.167 0.48 1;0.52 0 0.48 1;0.52 0 0.48 1;0 0 0 0"
          calcMode="spline"
        />
        <animate
          repeatCount="indefinite"
          dur="3.8833333s"
          begin="0s"
          xlinkHref="#_R_G_L_12_G_M"
          fill="freeze"
          attributeName="opacity"
          from="0"
          to="0"
          keyTimes="0;0.0686695;0.06867;0.4291845;0.4291846;1"
          values="0;0;1;1;0;0"
          keySplines="0 0 0 0;0 0 0 0;0 0 0 0;0 0 0 0;0 0 0 0"
          calcMode="spline"
        />
        <animateTransform
          repeatCount="indefinite"
          dur="3.8833333s"
          begin="0s"
          xlinkHref="#_R_G_L_11_G"
          fill="freeze"
          attributeName="transform"
          from="0 0"
          to="1 1"
          type="scale"
          additive="sum"
          keyTimes="0;0.0772532;0.2223176;0.3138197;0.4120172;1"
          values="0 0;0 0;1 1;0.7000000000000001 0.7000000000000001;1 1;1 1"
          keySplines="0.167 0.167 0.48 1;0.167 0.167 0.48 1;0.52 0 0.48 1;0.52 0 0.48 1;0 0 0 0"
          calcMode="spline"
        />
        <animate
          repeatCount="indefinite"
          dur="3.8833333s"
          begin="0s"
          xlinkHref="#_R_G_L_11_G_M"
          fill="freeze"
          attributeName="opacity"
          from="0"
          to="0"
          keyTimes="0;0.0772532;0.0772536;0.4291845;0.4291846;1"
          values="0;0;1;1;0;0"
          keySplines="0 0 0 0;0 0 0 0;0 0 0 0;0 0 0 0;0 0 0 0"
          calcMode="spline"
        />
        <animateTransform
          repeatCount="indefinite"
          dur="3.8833333s"
          begin="0s"
          xlinkHref="#_R_G_L_10_G"
          fill="freeze"
          attributeName="transform"
          from="0 0"
          to="1 1"
          type="scale"
          additive="sum"
          keyTimes="0;0.0858369;0.2309013;0.3224034;0.4206008;1"
          values="0 0;0.2 0.2;1 1;0.7000000000000001 0.7000000000000001;1 1;1 1"
          keySplines="0.167 0.167 0.833 0.833;0.167 0.167 0.48 1;0.52 0 0.48 1;0.52 0 0.48 1;0 0 0 0"
          calcMode="spline"
        />
        <animate
          repeatCount="indefinite"
          dur="3.8833333s"
          begin="0s"
          xlinkHref="#_R_G_L_10_G_M"
          fill="freeze"
          attributeName="opacity"
          from="0"
          to="0"
          keyTimes="0;0.0858369;0.0858373;0.4291845;0.4291846;1"
          values="0;0;1;1;0;0"
          keySplines="0 0 0 0;0 0 0 0;0 0 0 0;0 0 0 0;0 0 0 0"
          calcMode="spline"
        />
        <animateTransform
          repeatCount="indefinite"
          dur="3.8833333s"
          begin="0s"
          xlinkHref="#_R_G_L_9_G"
          fill="freeze"
          attributeName="transform"
          from="1 1"
          to="0 0"
          type="scale"
          additive="sum"
          keyTimes="0;0.4291845;0.527382;0.6188841;0.7639485;1"
          values="1 1;1 1;0.9 0.9;1 1;0 0;0 0"
          keySplines="0.52 0 0.48 1;0.52 0 0.48 1;0.52 0 0.48 1;0.52 0 0.833 0.833;0 0 0 0"
          calcMode="spline"
        />
        <animate
          repeatCount="indefinite"
          dur="3.8833333s"
          begin="0s"
          xlinkHref="#_R_G_L_9_G_M"
          fill="freeze"
          attributeName="opacity"
          from="0"
          to="1"
          keyTimes="0;0.4291845;0.429185;1"
          values="0;0;1;1"
          keySplines="0 0 0 0;0 0 0 0;0 0 0 0"
          calcMode="spline"
        />
        <animateTransform
          repeatCount="indefinite"
          dur="3.8833333s"
          begin="0s"
          xlinkHref="#_R_G_L_8_G"
          fill="freeze"
          attributeName="transform"
          from="1 1"
          to="0 0"
          type="scale"
          additive="sum"
          keyTimes="0;0.4377682;0.5359657;0.6274678;0.7725322;1"
          values="1 1;1 1;0.9 0.9;1 1;0 0;0 0"
          keySplines="0.52 0 0.48 1;0.52 0 0.48 1;0.52 0 0.48 1;0.52 0 0.833 0.833;0 0 0 0"
          calcMode="spline"
        />
        <animate
          repeatCount="indefinite"
          dur="3.8833333s"
          begin="0s"
          xlinkHref="#_R_G_L_8_G_M"
          fill="freeze"
          attributeName="opacity"
          from="0"
          to="1"
          keyTimes="0;0.4291845;0.429185;1"
          values="0;0;1;1"
          keySplines="0 0 0 0;0 0 0 0;0 0 0 0"
          calcMode="spline"
        />
        <animateTransform
          repeatCount="indefinite"
          dur="3.8833333s"
          begin="0s"
          xlinkHref="#_R_G_L_7_G"
          fill="freeze"
          attributeName="transform"
          from="1 1"
          to="0 0"
          type="scale"
          additive="sum"
          keyTimes="0;0.4463519;0.5450644;0.6360515;0.7811159;1"
          values="1 1;1 1;0.7000000000000001 0.7000000000000001;1 1;0 0;0 0"
          keySplines="0.52 0 0.48 1;0.52 0 0.48 1;0.52 0 0.48 1;0.52 0 0.833 0.833;0 0 0 0"
          calcMode="spline"
        />
        <animate
          repeatCount="indefinite"
          dur="3.8833333s"
          begin="0s"
          xlinkHref="#_R_G_L_7_G_M"
          fill="freeze"
          attributeName="opacity"
          from="0"
          to="1"
          keyTimes="0;0.4291845;0.429185;1"
          values="0;0;1;1"
          keySplines="0 0 0 0;0 0 0 0;0 0 0 0"
          calcMode="spline"
        />
        <animateTransform
          repeatCount="indefinite"
          dur="3.8833333s"
          begin="0s"
          xlinkHref="#_R_G_L_6_G"
          fill="freeze"
          attributeName="transform"
          from="1 1"
          to="0 0"
          type="scale"
          additive="sum"
          keyTimes="0;0.4549356;0.553133;0.6446352;0.7896996;1"
          values="1 1;1 1;0.7000000000000001 0.7000000000000001;1 1;0 0;0 0"
          keySplines="0.52 0 0.48 1;0.52 0 0.48 1;0.52 0 0.48 1;0.52 0 0.833 0.833;0 0 0 0"
          calcMode="spline"
        />
        <animate
          repeatCount="indefinite"
          dur="3.8833333s"
          begin="0s"
          xlinkHref="#_R_G_L_6_G_M"
          fill="freeze"
          attributeName="opacity"
          from="0"
          to="1"
          keyTimes="0;0.4291845;0.429185;1"
          values="0;0;1;1"
          keySplines="0 0 0 0;0 0 0 0;0 0 0 0"
          calcMode="spline"
        />
        <animateTransform
          repeatCount="indefinite"
          dur="3.8833333s"
          begin="0s"
          xlinkHref="#_R_G_L_5_G"
          fill="freeze"
          attributeName="transform"
          from="1 1"
          to="0 0"
          type="scale"
          additive="sum"
          keyTimes="0;0.4635193;0.5617167;0.6532189;0.7982832;1"
          values="1 1;1 1;0.7000000000000001 0.7000000000000001;1 1;0 0;0 0"
          keySplines="0.52 0 0.48 1;0.52 0 0.48 1;0.52 0 0.48 1;0.52 0 0.833 0.833;0 0 0 0"
          calcMode="spline"
        />
        <animate
          repeatCount="indefinite"
          dur="3.8833333s"
          begin="0s"
          xlinkHref="#_R_G_L_5_G_M"
          fill="freeze"
          attributeName="opacity"
          from="0"
          to="1"
          keyTimes="0;0.4291845;0.429185;1"
          values="0;0;1;1"
          keySplines="0 0 0 0;0 0 0 0;0 0 0 0"
          calcMode="spline"
        />
        <animateTransform
          repeatCount="indefinite"
          dur="3.8833333s"
          begin="0s"
          xlinkHref="#_R_G_L_4_G"
          fill="freeze"
          attributeName="transform"
          from="1 1"
          to="0 0"
          type="scale"
          additive="sum"
          keyTimes="0;0.472103;0.5703004;0.6618026;0.8068669;1"
          values="1 1;1 1;0.7000000000000001 0.7000000000000001;1 1;0 0;0 0"
          keySplines="0.52 0 0.48 1;0.52 0 0.48 1;0.52 0 0.48 1;0.52 0 0.833 0.833;0 0 0 0"
          calcMode="spline"
        />
        <animate
          repeatCount="indefinite"
          dur="3.8833333s"
          begin="0s"
          xlinkHref="#_R_G_L_4_G_M"
          fill="freeze"
          attributeName="opacity"
          from="0"
          to="1"
          keyTimes="0;0.4291845;0.429185;1"
          values="0;0;1;1"
          keySplines="0 0 0 0;0 0 0 0;0 0 0 0"
          calcMode="spline"
        />
        <animateTransform
          repeatCount="indefinite"
          dur="3.8833333s"
          begin="0s"
          xlinkHref="#_R_G_L_3_G"
          fill="freeze"
          attributeName="transform"
          from="1 1"
          to="0 0"
          type="scale"
          additive="sum"
          keyTimes="0;0.4806867;0.5788841;0.6703863;0.8154506;1"
          values="1 1;1 1;0.7000000000000001 0.7000000000000001;1 1;0 0;0 0"
          keySplines="0.52 0 0.48 1;0.52 0 0.48 1;0.52 0 0.48 1;0.52 0 0.833 0.833;0 0 0 0"
          calcMode="spline"
        />
        <animate
          repeatCount="indefinite"
          dur="3.8833333s"
          begin="0s"
          xlinkHref="#_R_G_L_3_G_M"
          fill="freeze"
          attributeName="opacity"
          from="0"
          to="1"
          keyTimes="0;0.4291845;0.429185;1"
          values="0;0;1;1"
          keySplines="0 0 0 0;0 0 0 0;0 0 0 0"
          calcMode="spline"
        />
        <animateTransform
          repeatCount="indefinite"
          dur="3.8833333s"
          begin="0s"
          xlinkHref="#_R_G_L_2_G"
          fill="freeze"
          attributeName="transform"
          from="1 1"
          to="0 0"
          type="scale"
          additive="sum"
          keyTimes="0;0.4892704;0.5874678;0.67897;0.8240343;1"
          values="1 1;1 1;0.7000000000000001 0.7000000000000001;1 1;0 0;0 0"
          keySplines="0.52 0 0.48 1;0.52 0 0.48 1;0.52 0 0.48 1;0.52 0 0.833 0.833;0 0 0 0"
          calcMode="spline"
        />
        <animate
          repeatCount="indefinite"
          dur="3.8833333s"
          begin="0s"
          xlinkHref="#_R_G_L_2_G_M"
          fill="freeze"
          attributeName="opacity"
          from="0"
          to="1"
          keyTimes="0;0.4291845;0.429185;1"
          values="0;0;1;1"
          keySplines="0 0 0 0;0 0 0 0;0 0 0 0"
          calcMode="spline"
        />
        <animateTransform
          repeatCount="indefinite"
          dur="3.8833333s"
          begin="0s"
          xlinkHref="#_R_G_L_1_G"
          fill="freeze"
          attributeName="transform"
          from="1 1"
          to="0 0"
          type="scale"
          additive="sum"
          keyTimes="0;0.4978541;0.5960515;0.6875536;0.832618;1"
          values="1 1;1 1;0.7000000000000001 0.7000000000000001;1 1;0 0;0 0"
          keySplines="0.52 0 0.48 1;0.52 0 0.48 1;0.52 0 0.48 1;0.52 0 0.833 0.833;0 0 0 0"
          calcMode="spline"
        />
        <animate
          repeatCount="indefinite"
          dur="3.8833333s"
          begin="0s"
          xlinkHref="#_R_G_L_1_G_M"
          fill="freeze"
          attributeName="opacity"
          from="0"
          to="1"
          keyTimes="0;0.4291845;0.429185;1"
          values="0;0;1;1"
          keySplines="0 0 0 0;0 0 0 0;0 0 0 0"
          calcMode="spline"
        />
        <animateTransform
          repeatCount="indefinite"
          dur="3.8833333s"
          begin="0s"
          xlinkHref="#_R_G_L_0_G"
          fill="freeze"
          attributeName="transform"
          from="1 1"
          to="0 0"
          type="scale"
          additive="sum"
          keyTimes="0;0.5064378;0.6046352;0.6961373;0.8412017;1"
          values="1 1;1 1;0.7000000000000001 0.7000000000000001;1 1;0 0;0 0"
          keySplines="0.52 0 0.48 1;0.52 0 0.48 1;0.52 0 0.48 1;0.52 0 0.833 0.833;0 0 0 0"
          calcMode="spline"
        />
        <animate
          repeatCount="indefinite"
          dur="3.8833333s"
          begin="0s"
          xlinkHref="#_R_G_L_0_G_M"
          fill="freeze"
          attributeName="opacity"
          from="0"
          to="1"
          keyTimes="0;0.4291845;0.429185;1"
          values="0;0;1;1"
          keySplines="0 0 0 0;0 0 0 0;0 0 0 0"
          calcMode="spline"
        />
        <animate
          attributeType="XML"
          attributeName="opacity"
          dur="4s"
          from="0"
          to="1"
          xlinkHref="#time_group"
        />
      </defs>
      <g id="_R_G">
        <g id="_R_G_L_19_G_M">
          <g id="_R_G_L_19_G" transform=" translate(39.351, 63.93)">
            <path
              id="_R_G_L_19_G_D_0_P_0"
              fill="#9569ff"
              fillOpacity="1"
              fillRule="nonzero"
              d=" M16.64 0 C16.64,0 0,-11.52 0,-11.52 C0,-11.52 -16.64,0 -16.64,0 C-16.64,0 0,11.52 0,11.52 C0,11.52 16.64,0 16.64,0z "
            />
          </g>
        </g>
        <g id="_R_G_L_18_G_M">
          <g id="_R_G_L_18_G" transform=" translate(22.707, 52.406)">
            <path
              id="_R_G_L_18_G_D_0_P_0"
              fill="#893cff"
              fillOpacity="1"
              fillRule="nonzero"
              d=" M16.64 0 C16.64,0 0,-11.52 0,-11.52 C0,-11.52 -16.64,0 -16.64,0 C-16.64,0 0,11.52 0,11.52 C0,11.52 16.64,0 16.64,0z "
            />
          </g>
        </g>
        <g id="_R_G_L_17_G_M">
          <g id="_R_G_L_17_G" transform=" translate(55.996, 52.406)">
            <path
              id="_R_G_L_17_G_D_0_P_0"
              fill="#a897ff"
              fillOpacity="1"
              fillRule="nonzero"
              d=" M16.64 0 C16.64,0 0,-11.52 0,-11.52 C0,-11.52 -16.64,0 -16.64,0 C-16.64,0 0,11.52 0,11.52 C0,11.52 16.64,0 16.64,0z "
            />
          </g>
        </g>
        <g id="_R_G_L_16_G_M">
          <g id="_R_G_L_16_G" transform=" translate(8.623, 40.882)">
            <path
              id="_R_G_L_16_G_D_0_P_0"
              fill="#7400e8"
              fillOpacity="1"
              fillRule="nonzero"
              d=" M14.08 0 C14.08,0 -2.56,-11.52 -2.56,-11.52 C-2.56,-11.52 -2.56,11.52 -2.56,11.52 C-2.56,11.52 14.08,0 14.08,0z "
            />
          </g>
        </g>
        <g id="_R_G_L_15_G_M">
          <g id="_R_G_L_15_G" transform=" translate(39.351, 40.882)">
            <path
              id="_R_G_L_15_G_D_0_P_0"
              fill="#8100f8"
              fillOpacity="1"
              fillRule="nonzero"
              d=" M16.64 0 C16.64,0 0,-11.52 0,-11.52 C0,-11.52 -16.64,0 -16.64,0 C-16.64,0 0,11.52 0,11.52 C0,11.52 16.64,0 16.64,0z "
            />
          </g>
        </g>
        <g id="_R_G_L_14_G_M">
          <g id="_R_G_L_14_G" opacity="0.99048" transform=" translate(70.08, 40.882)">
            <path
              id="_R_G_L_14_G_D_0_P_0"
              fill="#c5b5ff"
              fillOpacity="1"
              fillRule="nonzero"
              d=" M2.56 11.52 C2.56,11.52 2.56,-11.52 2.56,-11.52 C2.56,-11.52 -14.08,0 -14.08,0 C-14.08,0 2.56,11.52 2.56,11.52z "
            />
          </g>
        </g>
        <g id="_R_G_L_13_G_M">
          <g id="_R_G_L_13_G" opacity="0.96475" transform=" translate(22.707, 29.359)">
            <path
              id="_R_G_L_13_G_D_0_P_0"
              fill="#6e00d5"
              fillOpacity="1"
              fillRule="nonzero"
              d=" M16.64 0 C16.64,0 0,-11.52 0,-11.52 C0,-11.52 -16.64,0 -16.64,0 C-16.64,0 0,11.52 0,11.52 C0,11.52 16.64,0 16.64,0z "
            />
          </g>
        </g>
        <g id="_R_G_L_12_G_M">
          <g id="_R_G_L_12_G" opacity="0.93903" transform=" translate(55.996, 29.359)">
            <path
              id="_R_G_L_12_G_D_0_P_0"
              fill="#a795ff"
              fillOpacity="1"
              fillRule="nonzero"
              d=" M16.64 0 C16.64,0 0,-11.52 0,-11.52 C0,-11.52 -16.64,0 -16.64,0 C-16.64,0 0,11.52 0,11.52 C0,11.52 16.64,0 16.64,0z "
            />
          </g>
        </g>
        <g id="_R_G_L_11_G_M">
          <g id="_R_G_L_11_G" opacity="0.9133" transform=" translate(8.623, 17.835)">
            <path
              id="_R_G_L_11_G_D_0_P_0"
              fill="#590097"
              fillOpacity="1"
              fillRule="nonzero"
              d=" M14.08 0 C14.08,0 -2.56,-11.52 -2.56,-11.52 C-2.56,-11.52 -2.56,11.52 -2.56,11.52 C-2.56,11.52 14.08,0 14.08,0z "
            />
          </g>
        </g>
        <g id="_R_G_L_10_G_M">
          <g id="_R_G_L_10_G" opacity="0.8875700000000001" transform=" translate(70.08, 17.835)">
            <path
              id="_R_G_L_10_G_D_0_P_0"
              fill="#d1bdff"
              fillOpacity="1"
              fillRule="nonzero"
              d=" M2.56 11.52 C2.56,11.52 2.56,-11.52 2.56,-11.52 C2.56,-11.52 -14.08,0 -14.08,0 C-14.08,0 2.56,11.52 2.56,11.52z "
            />
          </g>
        </g>
        <g id="_R_G_L_9_G_M">
          <g id="_R_G_L_9_G" transform=" translate(39.351, 63.93)">
            <path
              id="_R_G_L_9_G_D_0_P_0"
              fill="#9569ff"
              fillOpacity="1"
              fillRule="nonzero"
              d=" M16.64 0 C16.64,0 0,-11.52 0,-11.52 C0,-11.52 -16.64,0 -16.64,0 C-16.64,0 0,11.52 0,11.52 C0,11.52 16.64,0 16.64,0z "
            />
          </g>
        </g>
        <g id="_R_G_L_8_G_M">
          <g id="_R_G_L_8_G" transform=" translate(22.707, 52.406)">
            <path
              id="_R_G_L_8_G_D_0_P_0"
              fill="#893cff"
              fillOpacity="1"
              fillRule="nonzero"
              d=" M16.64 0 C16.64,0 0,-11.52 0,-11.52 C0,-11.52 -16.64,0 -16.64,0 C-16.64,0 0,11.52 0,11.52 C0,11.52 16.64,0 16.64,0z "
            />
          </g>
        </g>
        <g id="_R_G_L_7_G_M">
          <g id="_R_G_L_7_G" transform=" translate(55.996, 52.406)">
            <path
              id="_R_G_L_7_G_D_0_P_0"
              fill="#a897ff"
              fillOpacity="1"
              fillRule="nonzero"
              d=" M16.64 0 C16.64,0 0,-11.52 0,-11.52 C0,-11.52 -16.64,0 -16.64,0 C-16.64,0 0,11.52 0,11.52 C0,11.52 16.64,0 16.64,0z "
            />
          </g>
        </g>
        <g id="_R_G_L_6_G_M">
          <g id="_R_G_L_6_G" transform=" translate(8.623, 40.882)">
            <path
              id="_R_G_L_6_G_D_0_P_0"
              fill="#7400e8"
              fillOpacity="1"
              fillRule="nonzero"
              d=" M14.08 0 C14.08,0 -2.56,-11.52 -2.56,-11.52 C-2.56,-11.52 -2.56,11.52 -2.56,11.52 C-2.56,11.52 14.08,0 14.08,0z "
            />
          </g>
        </g>
        <g id="_R_G_L_5_G_M">
          <g id="_R_G_L_5_G" transform=" translate(39.351, 40.882)">
            <path
              id="_R_G_L_5_G_D_0_P_0"
              fill="#8100f8"
              fillOpacity="1"
              fillRule="nonzero"
              d=" M16.64 0 C16.64,0 0,-11.52 0,-11.52 C0,-11.52 -16.64,0 -16.64,0 C-16.64,0 0,11.52 0,11.52 C0,11.52 16.64,0 16.64,0z "
            />
          </g>
        </g>
        <g id="_R_G_L_4_G_M">
          <g id="_R_G_L_4_G" opacity="0.99048" transform=" translate(70.08, 40.882)">
            <path
              id="_R_G_L_4_G_D_0_P_0"
              fill="#c5b5ff"
              fillOpacity="1"
              fillRule="nonzero"
              d=" M2.56 11.52 C2.56,11.52 2.56,-11.52 2.56,-11.52 C2.56,-11.52 -14.08,0 -14.08,0 C-14.08,0 2.56,11.52 2.56,11.52z "
            />
          </g>
        </g>
        <g id="_R_G_L_3_G_M">
          <g id="_R_G_L_3_G" opacity="0.96475" transform=" translate(22.707, 29.359)">
            <path
              id="_R_G_L_3_G_D_0_P_0"
              fill="#6e00d5"
              fillOpacity="1"
              fillRule="nonzero"
              d=" M16.64 0 C16.64,0 0,-11.52 0,-11.52 C0,-11.52 -16.64,0 -16.64,0 C-16.64,0 0,11.52 0,11.52 C0,11.52 16.64,0 16.64,0z "
            />
          </g>
        </g>
        <g id="_R_G_L_2_G_M">
          <g id="_R_G_L_2_G" opacity="0.93903" transform=" translate(55.996, 29.359)">
            <path
              id="_R_G_L_2_G_D_0_P_0"
              fill="#a795ff"
              fillOpacity="1"
              fillRule="nonzero"
              d=" M16.64 0 C16.64,0 0,-11.52 0,-11.52 C0,-11.52 -16.64,0 -16.64,0 C-16.64,0 0,11.52 0,11.52 C0,11.52 16.64,0 16.64,0z "
            />
          </g>
        </g>
        <g id="_R_G_L_1_G_M">
          <g id="_R_G_L_1_G" opacity="0.9133" transform=" translate(8.623, 17.835)">
            <path
              id="_R_G_L_1_G_D_0_P_0"
              fill="#590097"
              fillOpacity="1"
              fillRule="nonzero"
              d=" M14.08 0 C14.08,0 -2.56,-11.52 -2.56,-11.52 C-2.56,-11.52 -2.56,11.52 -2.56,11.52 C-2.56,11.52 14.08,0 14.08,0z "
            />
          </g>
        </g>
        <g id="_R_G_L_0_G_M">
          <g id="_R_G_L_0_G" opacity="0.8875700000000001" transform=" translate(70.08, 17.835)">
            <path
              id="_R_G_L_0_G_D_0_P_0"
              fill="#d1bdff"
              fillOpacity="1"
              fillRule="nonzero"
              d=" M2.56 11.52 C2.56,11.52 2.56,-11.52 2.56,-11.52 C2.56,-11.52 -14.08,0 -14.08,0 C-14.08,0 2.56,11.52 2.56,11.52z "
            />
          </g>
        </g>
      </g>
      <g id="time_group" />
    </svg>
  );
}
