import styled, { css } from "styled-components";
import ReactSelect from "react-select";
import {
  ContainerProps,
  SelectLabelProps,
  SelectComponentProps,
  SelectComponentWrapperProps,
  LabelTextProps,
} from "./Select.types";
import { getSize } from "../../utils/size";
import { Icon } from "../Icon";

export const Container = styled.div<ContainerProps>`
  color: ${(p) => p.theme.palette.layout.depth6};
  font-size: ${(p) => p.theme.fontSize.sm};
  width: ${(p) => p.width || (!p.flex && "27rem") || (p.integrated && "100%")};
`;

export const SelectComponentWrapper = styled.div<SelectComponentWrapperProps>`
  display: flex;
  position: relative;
  flex: 1;
`;

export const SelectComponent = styled(ReactSelect)<SelectComponentProps>`
  flex: 1;
  opacity: ${(p) => p.isDisabled && !p.readOnly && p.theme.opacity.opacity2};
  background-color: ${(p) => p.theme.palette.layout.depth3};
  border-radius: ${(p) => (p.integrated ? 0 : p.theme.radius.radius2)};

  .Select__control {
    width: 100%;
    max-width: ${(p) => p.width || (!p.flex && "27rem")};
    cursor: ${(p) => (p.isDisabled ? "not-allowed" : "pointer")};
    background-color: ${(p) => p.theme.palette.layout.depth3};
    border-radius: ${(p) => (p.integrated ? 0 : p.theme.radius.radius2)};
    box-shadow: ${(p) =>
      p.menuIsOpen && !p.integrated ? `0px 0px 4px ${p.theme.palette.primary.main}` : "none"};
    border: 1px solid ${(p) => (p.error ? p.theme.palette.danger.main : p.theme.palette.layout.depth3)};
    height: ${(p) => getSize(p.size, p.theme)};
    min-height: 2.1rem;
    overflow: hidden;

    &:hover {
      background-color: ${(p) => p.theme.palette.primary.opacity1};
      border: 1px solid ${(p) => (p.error ? p.theme.palette.danger.main : p.theme.palette.layout.depth3)};
    }
    &:focus-visible {
      border: 2px solid red;
    }
    &:focus {
      border: 2px solid red;
    }
  }

  .Select__value-container {
    padding: 0 0 0 ${(p) => p.theme.spacing.md};
    margin-left: ${(p) => p.isSearchable && "2rem"};
    opacity: ${(p) => p.readOnly && "0.4"};
  }

  .Select__input-container {
    color: ${(p) => p.theme.palette.layout.depth6};
  }

  .Select__placeholder {
    color: ${(p) => p.theme.palette.layout.depth5};
    overflow: hidden;
  }

  .Select__single-value {
    color: ${(p) => p.theme.palette.layout.depth6};
  }

  .Select__multi-value {
    max-width: 10rem;
    height: ${(p) => getSize(p.size, p.theme)};
    background: transparent;
    color: ${(p) => p.theme.palette.layout.depth6};
    align-items: center;
    margin: 0;
    &::after {
      content: ",";
    }

    &:nth-last-child(2)::after {
      content: "";
    }
  }

  .Select__multi-value__remove {
    display: none;
  }
  .Select__multi-value__label {
    color: ${(p) => p.theme.palette.layout.depth6};
    padding: 0 0 0 ${(p) => p.theme.spacing.xs2};
    font-size: 100%;
  }

  .Select__indicator {
    color: ${(p) => p.theme.palette.layout.depth6};
    opacity: ${(p) => p.readOnly && "0.4"};
    padding: 0;
    height: 100%;
    &:hover {
      color: ${(p) => p.theme.palette.layout.depth6};
    }
  }

  .Select__clear-indicator {
    display: none;
  }

  .Select__indicator-separator {
    display: none;
  }

  .Select__menu {
    background-color: ${(p) => p.theme.palette.layout.depth3};
    border-radius: ${(p) => (p.integrated ? 0 : p.theme.radius.radius2)};
    border: none;
    z-index: ${(p) => (p.zIndex ? p.zIndex : "auto")};
    padding: 0.2rem 0;
    ${(p) => p.integrated && "margin: 0"};
  }

  .Select__option {
    background-color: ${(p) => p.theme.palette.layout.depth3};
    cursor: pointer;

    &:hover {
      background-color: ${(p) => p.theme.palette.primary.opacity1};
    }
  }

  .css-mohuvp-dummyInput-DummyInput {
    height: 0;
  }
`;

export const Label = styled.label<SelectLabelProps>`
  position: relative;
  display: flex;
  flex-direction: ${(p) => (p.labelOnRight ? "row-reverse" : p.labelOnLeft ? "row" : "column")};
  align-items: ${(p) => (p.labelOnLeft || p.labelOnRight ? "center" : "left")};
`;

export const LabelText = styled.span<LabelTextProps>`
  flex: 1;
  margin-bottom: ${(p) => !p.labelOnLeft && !p.labelOnRight && p.theme.spacing.sm};
  display: flex;
  white-space: nowrap;
`;

export const RequiredLabel = styled.div`
  color: ${(p) => p.theme.palette.danger.main};
  margin-left: ${(p) => p.theme.spacing.xs2};
`;

export const BottomText = styled.div`
  display: flex;
`;

export const Error = styled.div`
  flex: 1;
  margin-top: ${(p) => p.theme.spacing.xs};
  color: ${(p) => p.theme.palette.danger.main};
`;

export const Spacer = styled.div`
  flex: 1;
`;

export const SearchIcon = styled(Icon)`
  position: absolute;
  left: 1rem;
  top: calc(50% - 0.5rem);
  height: 1rem;
  width: 1rem;
  pointer-events: none;
`;
