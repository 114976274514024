import { DefaultTheme } from "styled-components";
import { SelectSize } from "./utils.type";

export const getSize = (size: SelectSize, t: DefaultTheme) => {
  switch (size) {
    case "Small":
      return t.inputElementHeight.md;
    case "Large":
    default:
      return t.inputElementHeight.lg;
  }
};

export const getPadding = (t: DefaultTheme) => `${t.spacing.xs} ${t.spacing.sm}`;
