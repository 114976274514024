import styled from "styled-components";
import { CheckboxComponentProps, CheckboxContainerProps, CheckboxPlaceholderProps } from "./Checkbox.types";
import { Icon } from "../Icon";

export const CheckboxContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const Label = styled.label<CheckboxContainerProps>`
  width: fit-content;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  border: none;
  cursor: ${(p) => (p.disabled ? "not-allowed" : "pointer")};
  font-size: ${(p) => p.theme.fontSize.sm};
  color: ${(p) => (p.disabled ? p.theme.palette.layout.depth5 : p.theme.palette.layout.depth6)};
  position: relative;
`;

export const HiddenCheckbox = styled.input.attrs({ type: "checkbox" })<CheckboxComponentProps>`
  border: 0;
  clip: rect(0 0 0 0);
  clippath: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;
`;

export const CheckboxIcon = styled(Icon)`
  width: ${(p) => p.theme.fontSize.md};
  height: ${(p) => p.theme.fontSize.md};
  color: ${(p) => p.theme.palette.primary.contrastText};
`;

export const Checkbox = styled.div<CheckboxPlaceholderProps>`
  width: 1.125rem;
  height: 1.125rem;
  margin-right: ${(p) => p.theme.spacing.sm};
  border-radius: ${(p) => p.theme.radius.radius1};
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid ${(p) => (p.checked ? p.theme.palette.primary.main : p.theme.palette.layout.depth4)};
  background-color: ${(p) => (p.checked ? p.theme.palette.primary.main : p.theme.palette.layout.depth3)};
  transition: all 150ms;

  ${HiddenCheckbox}:focus + & {
    box-shadow: 0 0 0 3px ${(p) => p.theme.palette.primary.main};
  }

  ${CheckboxIcon} {
    visibility: ${(props) => (props.checked ? "visible" : "hidden")};
  }
`;
export const BottomLabel = styled.p`
  margin-top: ${(p) => p.theme.spacing.xs};
  font-size: ${(p) => p.theme.fontSize.xs};
  font-weight: ${(p) => p.theme.fontWeight.regular};
  line-height: ${(p) => p.theme.lineHeight.xs};
  color: ${(p) => p.theme.palette.danger.main};
`;

export const Required = styled.span`
  color: ${(p) => p.theme.palette.danger.main};
  margin-left: 4px;
`;
