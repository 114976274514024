import { faChevronDown } from "@fortawesome/pro-light-svg-icons";
import React from "react";
import { components, OptionProps } from "react-select";
import { Checkbox } from "../../Checkbox/Checkbox";
import * as Styled from "./MultiComponents.styles";

const { Option, DropdownIndicator } = components;

export const MultiOption = (props: OptionProps) => {
  return (
    <Option {...props}>
      <Styled.Option>
        <Checkbox name={props.label} defaultValue={props.isSelected} checked={props.isSelected} readOnly />
        <label>{props.label}</label>
      </Styled.Option>
    </Option>
  );
};

export const MultiDropdownIndicator = (props: any) => {
  const { menuIsOpen, size } = props.selectProps;
  const numSelectedValues = props.getValue().length;
  return (
    <DropdownIndicator {...props}>
      <Styled.Content $size={size}>
        <Styled.NumSelectedValues>{numSelectedValues}st</Styled.NumSelectedValues>
        <Styled.IndicatorIcon icon={faChevronDown} $isOpen={menuIsOpen} />
      </Styled.Content>
    </DropdownIndicator>
  );
};
