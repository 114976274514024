import React, { forwardRef, ReactElement, useState } from "react";
import * as Styled from "./Select.style";
import { GroupedOption, SelectProps } from "./Select.types";
import { faSearch } from "@fortawesome/pro-light-svg-icons";
import { MultiDropdownIndicator, MultiOption } from "./MultiComponents/MultiComponents";
import { SingleDropdownIndicator } from "./SingleComponents/SingleComponents";
import { IconOption, IconValueContainer } from "./IconComponents/IconComponents";
import { components } from "react-select";

export const Select = forwardRef<HTMLInputElement, SelectProps>((props: SelectProps, ref): ReactElement => {
  const {
    label,
    flex,
    error,
    required,
    className,
    width,
    disabled = false,
    searchable = false,
    labelOnLeft = false,
    labelOnRight = false,
    multi = false,
    integrated = false,
    readOnly = false,
    withIcons = false,
    size = "Small",
    zIndex,
    ...selectProps
  } = props;
  const [isOpen, setIsOpen] = useState(false);

  const customComponents = multi
    ? {
        Option: MultiOption,
        DropdownIndicator: MultiDropdownIndicator,
      }
    : {
        Option: withIcons ? IconOption : components.Option,
        ValueContainer: withIcons ? IconValueContainer : components.ValueContainer,
        DropdownIndicator: SingleDropdownIndicator,
      };

  const formatGroupLabel = (data: GroupedOption) => (
    <div>
      <span>{data.label}</span>
    </div>
  );

  return (
    <Styled.Container width={width} flex={flex} integrated={integrated} className={className}>
      <Styled.Label labelOnLeft={labelOnLeft} labelOnRight={labelOnRight}>
        {(label || required) && (
          <Styled.LabelText labelOnLeft={labelOnLeft} labelOnRight={labelOnRight}>
            {label} {required && <Styled.RequiredLabel>*</Styled.RequiredLabel>}
          </Styled.LabelText>
        )}
        <Styled.SelectComponentWrapper isOpen={isOpen}>
          <Styled.SelectComponent<any, any>
            menuIsOpen={isOpen}
            size={size}
            integrated={integrated}
            isMulti={multi}
            classNamePrefix="Select"
            formatGroupLabel={formatGroupLabel}
            onMenuClose={() => setIsOpen(false)}
            onMenuOpen={() => setIsOpen(true)}
            closeMenuOnSelect={!multi}
            hideSelectedOptions={false}
            error={error}
            isDisabled={disabled || readOnly}
            isSearchable={searchable}
            readOnly={readOnly}
            zIndex={zIndex || 1}
            components={customComponents}
            width={width}
            flex={flex}
            {...selectProps}
          />
          {searchable && <Styled.SearchIcon icon={faSearch} />}
        </Styled.SelectComponentWrapper>
      </Styled.Label>
      <Styled.BottomText>
        {labelOnLeft && <Styled.Spacer />}
        {error && <Styled.Error>{error}</Styled.Error>}
      </Styled.BottomText>
    </Styled.Container>
  );
});

Select.displayName = "Select";
