import styled from "styled-components";
import { getSize } from "../../utils/size";
import { Icon } from "../Icon";
import {
  CalendarWrapperProps,
  DatePickerLabelProps,
  DateInputWrapperProps,
  DatePickerContainerProps,
  CalendarIconProps,
} from "./DatePicker.types";

export const DatePickerContainer = styled.div<DatePickerContainerProps>`
  display: flex;
  position: relative;
  flex-direction: column;
  width: ${(p) => !p.flex && "434px"};
  flex: ${(p) => p.flex};
  color: ${(p) => p.theme.palette.layout.depth6};
`;

export const DatePickerLabel = styled.label<DatePickerLabelProps>`
  padding: 0;
  display: flex;
  flex-direction: ${(p) => (p.isLeftLabel ? "row" : "column")};
  align-items: ${(p) => p.isLeftLabel && "center"};
  font-size: ${(p) => p.theme.fontSize.sm};
  line-height: 20px;
`;

export const DatePickerLabelText = styled.span`
  flex: 1;
`;

export const DatePickerWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  flex: 1;
`;

export const DateInputWrapper = styled.div<DateInputWrapperProps>`
  display: flex;
  position: relative;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  font-size: ${(p) => p.theme.fontSize.sm};
  line-height: ${(p) => p.theme.lineHeight.sm};
  height: ${(p) => getSize(p.size, p.theme)};
  background-color: ${(p) => p.theme.palette.layout.depth3};
  border-radius: ${(p) => p.theme.radius.radius2};
  border: 1px solid ${(p) => (p.isError ? p.theme.palette.danger.main : p.theme.palette.layout.depth3)};
  margin-top: ${(p) => p.theme.spacing.sm};
  margin-bottom: ${(p) => p.theme.spacing.sm};
`;

export const DateInput = styled.input`
  position: relative;
  display: flex;
  width: 100%;
  flex: 1;
  justify-content: space-between;
  align-items: center;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-family: inherit;
  background-color: transparent;
  border: none;
  color: ${(p) => p.theme.palette.layout.depth6};
  padding-left: ${(p) => p.theme.spacing.md};
  line-height: ${(p) => p.theme.lineHeight.sm};
  &:focus-visible {
    outline: none;
  }
  &::-webkit-calendar-picker-indicator {
    display: none;
    -webkit-appearance: none;
  }
`;

export const CalendarIcon = styled(Icon)<CalendarIconProps>`
  position: absolute;
  right: 0;
  z-index: 1;
  height: calc(${(p) => getSize(p.size, p.theme)});
  width: 1rem;
  padding: ${(p) => `0 ${p.theme.spacing.sm}  0 ${p.theme.spacing.sm}`};
  border-radius: ${(p) => `0 ${p.theme.radius.radius2} ${p.theme.radius.radius2} 0`};
  color: ${(p) => p.theme.palette.layout.depth6};
  line-height: ${(p) => p.theme.lineHeight.sm};
  overflow: hidden;
  cursor: pointer;
  &:hover {
    background-color: ${(p) => p.theme.palette.primary.opacity1};
  }
`;

export const CalendarIconBackground = styled.div`
  position: absolute;
  right: 0;
  width: 1rem;
  padding: ${(p) => p.theme.spacing.sm};
  border-radius: ${(p) => `0 ${p.theme.radius.radius2} ${p.theme.radius.radius2} 0`};
  background-color: ${(p) => p.theme.palette.layout.depth3};
`;

export const CalendarWrapper = styled.div<CalendarWrapperProps>`
  position: absolute;
  z-index: ${(p) => (p.zIndex ? p.zIndex : 999)};
  top: calc(${(p) => getSize(p.size, p.theme)} + ${(p) => p.theme.spacing.md});
  left: 0;
  right: 0;
  margin-top: ${(p) => p.theme.spacing.xs3};
  /* bottom: 50px; */
`;
