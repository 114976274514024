import React, { useEffect, useState } from "react";

export function useClickedOutside(ref?: React.RefObject<HTMLDivElement>, onClickedOutside?: () => void) {
  const [hasClickedOutside, setHasClickedOutside] = useState(false);

  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (!(event.target instanceof Element)) return;
      if (ref?.current && !ref.current.contains(event.target)) {
        setHasClickedOutside(true);
        onClickedOutside?.();
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref]);

  return hasClickedOutside;
}
