import styled, { css, DefaultTheme } from "styled-components";
import { RadioContainerProps, RadioButtonType } from "./Radio.types";

const getBackgroundColor = (p: RadioButtonType & { theme: DefaultTheme }) => {
  return p.checked ? p.theme.palette.primary.main : p.theme.palette.layout.depth3;
};

export const StyledRadioButton = styled.input<RadioButtonType>`
  -webkit-appearance: none;
  appearance: none;
  margin: 0;
  margin-right: ${(p) => p.theme.spacing.sm};
  width: ${(p) => p.theme.spacing.md};
  height: ${(p) => p.theme.spacing.md};
  border: 1px solid ${(p) => (p.checked ? p.theme.palette.primary.main : p.theme.palette.layout.depth4)};
  background-color: ${(p) => getBackgroundColor(p)};
  border-radius: ${(p) => p.theme.radius.radius6};
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: ${(p) => (p.disabled ? "not-allowed" : "pointer")};

  ${(p) =>
    p.checked &&
    css`
      &::before {
        content: "";
        display: inline-block;
        width: 0.4rem;
        height: 0.4rem;
        border-radius: ${(p) => p.theme.radius.radius6};
        background-color: ${(p) => p.theme.palette.primary.contrastText};
      }
    `}
`;

export const StyledLabel = styled.label<{ disabled?: boolean }>`
  color: ${(p) => p.theme.palette.layout.depth6};
  font-size: ${(p) => p.theme.fontSize.sm};
  cursor: ${(p) => (p.disabled ? "not-allowed" : "pointer")};
`;

export const StyledContainer = styled.div<RadioContainerProps>`
  display: flex;
  align-items: center;
  width: fit-content;
  margin-bottom: ${(p) => p.theme.spacing.sm};
  opacity: ${(p) => p.disabled && p.theme.opacity.opacity2};

  &:last-child {
    margin-bottom: 0;
  }
`;
