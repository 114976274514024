import React, { useState, forwardRef } from "react";
import * as Styled from "./Input.style";
import { InputProps } from "./Input.types";
import { NumberSelector } from "./NumberSelector";
import { IconType } from "../Icon";
import { v4 as uuidv4 } from "uuid";
import { Spinner } from "../Spinner";
import { defaultTheme } from "../../theme";
import { IconProp } from "@fortawesome/fontawesome-svg-core";

const CustomIcon = (icon?: IconType | (IconProp & IconType), height?: string) => {
  if (!icon) return null;
  if (React.isValidElement(icon)) return icon;
  return <Styled.Icon icon={icon} height={height} />;
};

export const Input = forwardRef<HTMLInputElement, InputProps>((props, ref) => {
  const uniqueKey = uuidv4();
  const {
    label,
    flex,
    error,
    helpText,
    cornerHint,
    addonText,
    icon,
    disabled,
    numberSelector,
    labelOnLeft = false,
    labelOnRight = false,
    size = "Small",
    className,
    iconOnLeft = false,
    required,
    onEnterKeyDown,
    type = "text",
    name,
    id = uniqueKey,
    placeholder,
    onChange,
    onBlur,
    step,
    value,
    readOnly,
    defaultValue,
    max,
    min,
    iconHeight,
    width,
    isLoading,
  } = props;

  const [isInFocus, setIsInFocus] = useState(false);

  const handleStepUp = () => {
    const inputElement = document.getElementById(id) as HTMLInputElement;
    inputElement?.stepUp();
  };

  const handleStepDown = () => {
    const inputElement = document.getElementById(id) as HTMLInputElement;
    inputElement?.stepDown();
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLElement>) => {
    switch (event.key) {
      case "Enter":
        onEnterKeyDown && onEnterKeyDown();
    }
  };

  return (
    <Styled.Container
      isError={!!error}
      flex={flex}
      width={width}
      label={label}
      cornerHint={cornerHint}
      labelOnLeft={labelOnLeft}
      labelOnRight={labelOnRight}
      className={className}>
      {(label || required) && (
        <Styled.Label labelOnLeft={labelOnLeft} labelOnRight={labelOnRight} htmlFor={id}>
          {label}
          {required && <Styled.Required>*</Styled.Required>}
        </Styled.Label>
      )}
      <Styled.InputContent>
        {cornerHint && <Styled.CornerHint>{cornerHint}</Styled.CornerHint>}
        <Styled.InputWrapper
          isError={!!error}
          isInFocus={isInFocus}
          hasHelpText={!!helpText}
          hasLabel={!!label}
          hasCornerHint={!!cornerHint}
          size={size}>
          <Styled.ExtraInputWrapper size={size} iconOnLeft={iconOnLeft}>
            {icon && !isLoading && (
              <Styled.IconWrapper iconOnLeft={iconOnLeft}>{CustomIcon(icon, iconHeight)}</Styled.IconWrapper>
            )}
            <Styled.Input
              type={numberSelector ? "number" : type}
              id={id}
              name={name}
              autoComplete="off"
              onChange={onChange}
              onBlur={onBlur}
              onKeyDown={handleKeyDown}
              onFocus={() => setIsInFocus(true)}
              disabled={disabled || readOnly || isLoading}
              step={step}
              value={value}
              addonText={addonText}
              icon={icon}
              ref={ref}
              placeholder={placeholder}
              readOnly={readOnly}
              defaultValue={defaultValue}
              max={max}
              min={min}
            />
            {isLoading && (
              <Styled.SpinnerContainer>
                <Spinner height={defaultTheme.inputElementHeight.sm} />
              </Styled.SpinnerContainer>
            )}
          </Styled.ExtraInputWrapper>
          {numberSelector && (
            <NumberSelector
              disabled={disabled}
              roundedBorders={numberSelector && !addonText}
              onStepUp={() => handleStepUp()}
              onStepDown={() => handleStepDown()}
            />
          )}

          {addonText && (
            <Styled.Addon isError={!!error}>
              <span>{addonText}</span>
            </Styled.Addon>
          )}
        </Styled.InputWrapper>
        {error ? (
          <Styled.BottomLabel isError>{error}</Styled.BottomLabel>
        ) : (
          <Styled.BottomLabel>{helpText}</Styled.BottomLabel>
        )}
      </Styled.InputContent>
    </Styled.Container>
  );
});

Input.displayName = "Input";
