import React, { forwardRef, useEffect, useState } from "react";
import * as Styled from "./Switch.style";
import { SwitchProps } from "./Switch.types";

export const Switch = forwardRef<HTMLInputElement, SwitchProps>((props, ref) => {
  const {
    label,
    name,
    onChange,
    onBlur,
    disabled,
    id,
    errorText,
    readOnly,
    checked = false,
    required,
  } = props;

  const [toggled, setToggled] = useState(checked);

  useEffect(() => {
    setToggled(toggled);
  }, [document.getElementsByName(name)?.[0]]);

  const handleChange = () => {
    if (!disabled || !readOnly) {
      setToggled((prev) => !prev);
    }
  };

  return (
    <Styled.SwitchContainer>
      <Styled.Label htmlFor={id} onChange={handleChange} disabled={disabled} readOnly={readOnly}>
        <Styled.HiddenCheckbox
          name={name}
          id={id}
          onChange={onChange}
          onBlur={onBlur}
          ref={ref}
          checked={checked}
          disabled={disabled || readOnly}
        />
        <Styled.Switch toggled={toggled}>
          <Styled.SwitchHandle toggled={toggled} />
        </Styled.Switch>
        {label && (
          <span>
            {label}
            {required && <Styled.Required>*</Styled.Required>}
          </span>
        )}
      </Styled.Label>
      {errorText ? (
        <Styled.BottomLabel> {errorText}</Styled.BottomLabel>
      ) : (
        <Styled.BottomLabel> </Styled.BottomLabel>
      )}
    </Styled.SwitchContainer>
  );
});

Switch.displayName = "Switch";
