import React, { useState, ChangeEvent, useRef, forwardRef } from "react";
import { Calendar } from "./Calendar";
import { DatePickerProps } from "./DatePicker.types";
import * as Styled from "./DatePicker.styles";
import { faCalendar } from "@fortawesome/pro-light-svg-icons";
import { formatYYYYMMDD } from "../../utils/date";
import { v4 as uuidv4 } from "uuid";

export const DatePicker = forwardRef<HTMLInputElement, DatePickerProps>((props, ref) => {
  const {
    label,
    leftLabel,
    size = "Large",
    flex,
    onSelectDate,
    onChange,
    onBlur,
    name,
    zIndex,
    defaultValue,
  } = props;

  let initialDate: Date;
  if (defaultValue && !isNaN(Date.parse(defaultValue))) {
    initialDate = new Date(defaultValue);
  } else {
    initialDate = new Date();
  }

  const [date, setDate] = useState<Date>(initialDate);
  const uniqueKey = useRef(uuidv4());
  const [isOpen, setIsOpen] = useState(false);
  const datePickerRef = useRef<HTMLDivElement>(null);

  const inputElement = document.getElementById(uniqueKey.current);
  const handleClickOnDateChange = (pickedDate: Date) => {
    setDate(pickedDate);
    onSelectDate?.(pickedDate);
    inputElement.focus({ preventScroll: true }); // Todo: prevent scroll is not working properly
  };

  const handleInputOnDateChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    if (value) {
      setDate(new Date(value));
      onSelectDate?.(new Date(value));
      onChange(e);
    }
  };

  return (
    <Styled.DatePickerContainer ref={datePickerRef} flex={flex}>
      <Styled.DatePickerLabel isLeftLabel={leftLabel}>
        <Styled.DatePickerLabelText>{label}</Styled.DatePickerLabelText>
        <Styled.DatePickerWrapper>
          <Styled.DateInputWrapper size={size}>
            <Styled.DateInput
              type="date"
              max="3000-12-31"
              value={formatYYYYMMDD(date)}
              ref={ref}
              name={name}
              id={uniqueKey.current}
              onBlur={onBlur}
              onChange={handleInputOnDateChange}
              onClick={(event) => event.preventDefault()}
            />
            <Styled.CalendarIcon
              icon={faCalendar}
              size={size}
              onClick={() => setIsOpen(!isOpen)}
              data-testid="calendar-icon"
            />
            <Styled.CalendarIconBackground />
            {isOpen && (
              <Styled.CalendarWrapper zIndex={zIndex} size={size}>
                <Calendar
                  date={date}
                  onChange={handleClickOnDateChange}
                  onClose={() => setIsOpen(false)}
                  datePickerRef={datePickerRef}
                />
              </Styled.CalendarWrapper>
            )}
          </Styled.DateInputWrapper>
        </Styled.DatePickerWrapper>
      </Styled.DatePickerLabel>
    </Styled.DatePickerContainer>
  );
});

DatePicker.displayName = "DatePicker";
