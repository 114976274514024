import styled from "styled-components";
import { Button } from "../../Button";
import { Icon } from "../../Icon";
import { Input } from "../../Input";

export const FilterWrapper = styled.div<{ isOpen: boolean }>`
  display: flex;
  flex-direction: ${(p) => (p.isOpen ? "column" : "row")};
  padding-bottom: ${(p) => p.theme.spacing.xl};

  @media screen and (max-width: 700px) {
    flex-direction: column;
    align-items: start;
  }
`;

export const FilterNavigation = styled.div<{ fullWidth: boolean; flexEnd: boolean }>`
  display: flex;
  align-items: "center";
  justify-content: ${(p) => (p.flexEnd ? "end" : "space-between")};
  flex-direction: row;
  flex-wrap: wrap;
  width: ${(p) => p.fullWidth && "100%"};

  @media screen and (max-width: 700px) {
    flex-direction: column;
    align-items: start;
  }
`;

export const FilterContent = styled.div`
  display: flex;
  flex-direction: row;
  background-color: ${(p) => p.theme.palette.layout.depth2};
  border: 1px solid ${(p) => p.theme.palette.layout.depth7};
  border-radius: ${(p) => p.theme.radius.radius2};
`;

export const FilterButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: ${(p) => p.theme.spacing.xs2};
  padding: ${(p) => p.theme.spacing.sm};
  height: ${(p) => p.theme.inputElementHeight.xl};
  background-color: transparent;
  background-repeat: no-repeat;
  border: none;
  overflow: hidden;
  outline: none;
  cursor: pointer;
`;

export const P = styled.p`
  font-size: ${(p) => p.theme.fontSize.lg};
  line-height: ${(p) => p.theme.lineHeight.xl2};
  font-weight: ${(p) => p.theme.fontWeight.bold};
  color: ${(p) => p.theme.palette.layout.depth6};
`;

export const FilterIcon = styled(Icon)`
  color: white;
  width: ${(p) => p.theme.lineHeight.sm};
`;

export const FilterButtons = styled(Button)`
  max-width: 10rem;

  @media screen and (max-width: 700px) {
    min-width: 7rem;
  }
`;

export const FilterSearchInput = styled(Input)`
  margin: ${(p) => p.theme.spacing.sm} 0;
  @media screen and (max-width: 500px) {
    width: 100%;
  }
`;

export const FilterButtonsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  gap: ${(p) => p.theme.spacing.xs};

  @media screen and (min-width: 800px) {
    flex: 1;
  }

  @media screen and (max-width: 500px) {
    padding: ${(p) => p.theme.spacing.sm} 0;
    margin: ${(p) => p.theme.spacing.sm} 0;
    flex-wrap: noWrap;
    justify-content: start;
    width: 100%;
    overflow: scroll;
  }
`;

export const FilterCheckboxesWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: ${(p) => p.theme.spacing.xs};
`;
