import styled from "styled-components";
import { Icon } from "./Icon";

export const Wrapper = styled.div`
  :first-of-type svg {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
`;

export const TopIcon = styled(Icon)`
  &&& {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -72%);
    width: 0.875rem;
    height: 0.875rem;
  }
`;