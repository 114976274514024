import styled from "styled-components";
import { LinkProps } from "./Link.types";

export const StyledLink = styled.a<LinkProps>`
  font-size: ${(p) => p.theme.fontSize.xs};
  font-weight: ${(p) => p.theme.fontWeight.bold};
  line-height: ${(p) => p.theme.lineHeight.xs};
  background-color: transparent;
  color: ${(p) => p.theme.palette.primary.main};
  cursor: pointer;
  &:hover {
    color: ${(p) => p.theme.palette.primary.dark1};
  }
  &:active {
    color: ${(p) => p.theme.palette.primary.dark2};
  }
`;
