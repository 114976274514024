import React, { useState, ChangeEvent, useRef, forwardRef, useEffect } from "react";
import { TimePickerProps } from "./TimePicker.types";
import * as Styled from "./TimePicker.styles";
import { faClock } from "@fortawesome/pro-light-svg-icons";
import { v4 as uuidv4 } from "uuid";
import { Button } from "../Button";
import { TimeDropdownColumn } from "./TimePicker.styles";
import { useClickedOutside } from "../../hooks/useClickedOutside";

export const TimePicker = forwardRef<HTMLInputElement, TimePickerProps>((props, ref) => {
  const { label, leftLabel, size = "Large", flex, onSelectTime, onChange, onBlur, name, zIndex } = props;
  const [hour, setHour] = useState<number>(new Date().getHours());
  const [minute, setMinute] = useState<number>(new Date().getMinutes());
  const uniqueKey = useRef(uuidv4());
  const [isOpen, setIsOpen] = useState(false);
  const timePickerRef = useRef<HTMLDivElement>(null);

  const handleClickOnHourChange = (newHour: number) => {
    onSelectTime?.(newHour.toString());
    setHour(newHour);
  };

  const handleClickOnMinuteChange = (newMinute: number) => {
    onSelectTime?.(newMinute.toString());
    setMinute(newMinute);
  };

  const handleInputOnDateChange = (e: ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
  };

  useClickedOutside(timePickerRef, () => setIsOpen(false));

  const formatTimeString = (number: number) => {
    return number.toString().length > 1 ? number.toString() : "0" + number.toString();
  };

  return (
    <Styled.TimePickerContainer ref={timePickerRef} flex={flex}>
      <Styled.TimePickerLabel isLeftLabel={leftLabel}>
        <Styled.TimePickerLabelText>{label}</Styled.TimePickerLabelText>
        <Styled.TimePickerWrapper>
          <Styled.TimeInputWrapper size={size}>
            <Styled.TimeInput
              type="time"
              ref={ref}
              name={name}
              id={uniqueKey.current}
              onBlur={onBlur}
              onChange={handleInputOnDateChange}
              value={formatTimeString(hour) + ":" + formatTimeString(minute)}
              onClick={(event) => event.preventDefault()}
            />
            <Styled.ClockIcon
              icon={faClock}
              onClick={() => setIsOpen(!isOpen)}
              data-testid="calendar-icon"
              size={size}
            />
            <Styled.ClockIconBackground />
            {isOpen && (
              <Styled.TimeDropdownWrapper zIndex={zIndex} size={size}>
                <TimeDropdownColumn>
                  {Array.from(Array(24).keys()).map((index) => {
                    return (
                      <Button
                        key={index}
                        title={formatTimeString(index)}
                        size="Small"
                        fullWidth={true}
                        kind={hour === index ? "Solid" : "Ghost"}
                        onClick={() => handleClickOnHourChange(index)}
                      />
                    );
                  })}
                </TimeDropdownColumn>
                <TimeDropdownColumn>
                  {Array.from(Array(60).keys()).map((index) => {
                    return (
                      <Button
                        key={index}
                        title={formatTimeString(index)}
                        size="Small"
                        fullWidth={true}
                        kind={minute === index ? "Solid" : "Ghost"}
                        onClick={() => handleClickOnMinuteChange(index)}
                      />
                    );
                  })}
                </TimeDropdownColumn>
              </Styled.TimeDropdownWrapper>
            )}
          </Styled.TimeInputWrapper>
        </Styled.TimePickerWrapper>
      </Styled.TimePickerLabel>
    </Styled.TimePickerContainer>
  );
});

TimePicker.displayName = "DatePicker";
