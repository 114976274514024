import styled from "styled-components";
import { ContentProps, IndicatorIconProps } from "./SingleComponents.types";
import { getSize } from "../../../utils/size";
import { Icon } from "../../Icon";

export const Content = styled.div<ContentProps>`
  display: flex;
  align-items: center;
  height: ${(p) => getSize(p.$size, p.theme)};
  padding: 0 ${(p) => p.theme.spacing.sm};
`;

export const IndicatorIcon = styled(Icon)<IndicatorIconProps>`
  height: 1rem;
  width: 1rem;
  pointer-events: none;
  transition: all 0.3s ease-out;
  transform: ${(p) => (p.$isOpen ? `rotate(180deg)` : `rotate(0deg)`)};
`;
