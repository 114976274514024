import React from "react";
import * as Styled from "./Tabs.styles";
import { TabsProps, TabProps } from "./Tabs.types";
import { Card } from "../Card";
import styled from "styled-components";

const StyledCard = styled(Card)`
  border-top-left-radius: 0;
  align-items: flex-start;
  justify-content: flex-start;

  padding: ${({ theme }) => theme.spacing.xl};

  background-color: ${({ theme }) => theme.palette.layout.depth3};
`;

function Tab({ onClick, label, active }: TabProps) {
  return (
    <Styled.Tab onClick={onClick} active={active}>
      <Styled.TabLabel>{label.toUpperCase()}</Styled.TabLabel>
      <span />
    </Styled.Tab>
  );
}

export function Tabs({ tabs, handleViewChange, value, children }: TabsProps) {
  return (
    <Styled.TabsWrapper>
      <Styled.Tabs>
        {tabs.map(({ value: v, label }) => (
          <Tab key={v} label={label} active={v === value} onClick={() => handleViewChange(v)} />
        ))}
      </Styled.Tabs>
      <StyledCard>{children}</StyledCard>
    </Styled.TabsWrapper>
  );
}
