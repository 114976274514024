import React, { useEffect } from "react";
import * as Styled from "./Calendar.styles";
import { CalendarProps } from "./Calendar.types";
import { Icon } from "../../Icon";
import { faChevronLeft, faChevronRight } from "@fortawesome/pro-light-svg-icons";
import { useClickedOutside } from "../../../hooks/useClickedOutside";

export const Calendar = (props: CalendarProps) => {
  const { flex, date, datePickerRef, onChange, onClose } = props;
  const hasClickedOutside = useClickedOutside(datePickerRef);

  useEffect(() => {
    if (hasClickedOutside) {
      onClose?.();
    }
  }, [hasClickedOutside]);

  const handleOnChange = (pickedDate: Date) => {
    onChange(pickedDate);
  };

  const formatDate = (date: Date, locale: string) => {
    const dateName = date.toLocaleString(locale, { weekday: "short" }).charAt(0);
    return dateName;
  };

  return (
    <Styled.ReactCalendar
      locale="sv-se"
      formatShortWeekday={(locale: string, date: Date) => formatDate(date, "sv-se")}
      showNeighboringMonth={false}
      prevLabel={<Icon icon={faChevronLeft} />}
      prev2Label={null}
      nextLabel={<Icon icon={faChevronRight} />}
      next2Label={null}
      flex={flex}
      onChange={handleOnChange}
      value={date}
    />
  );
};
