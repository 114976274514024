import React, { ChangeEvent, forwardRef, useEffect, useRef, useState } from "react";
import * as Styled from "./Checkbox.style";
import { CheckboxProps } from "./Checkbox.types";
import { faCheck } from "@fortawesome/pro-light-svg-icons";

export const Checkbox = forwardRef<HTMLInputElement, CheckboxProps>((props, ref) => {
  const {
    label,
    name,
    onChange,
    onBlur,
    disabled,
    id,
    errorText,
    readOnly,
    required,
    defaultValue,
    checked: _checked,
  } = props;

  const [checked, setChecked] = useState(defaultValue || false);

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    onChange?.(e);
    setChecked(e.target.checked);
  };

  return (
    <Styled.CheckboxContainer>
      <Styled.Label htmlFor={id} disabled={disabled} readOnly={readOnly}>
        <Styled.HiddenCheckbox
          name={name}
          id={id}
          ref={ref}
          onBlur={onBlur}
          disabled={disabled || readOnly}
          readOnly={readOnly}
          checked={_checked === undefined ? checked : _checked}
          onChange={handleChange}
        />
        <Styled.Checkbox checked={_checked === undefined ? checked : _checked}>
          <Styled.CheckboxIcon icon={faCheck} />
        </Styled.Checkbox>
        {label && (
          <span>
            {label}
            {required && <Styled.Required>*</Styled.Required>}
          </span>
        )}
      </Styled.Label>
      {errorText && <Styled.BottomLabel> {errorText}</Styled.BottomLabel>}
    </Styled.CheckboxContainer>
  );
});

Checkbox.displayName = "Checkbox";
