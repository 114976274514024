import styled from "styled-components";

export const Row = styled.div`
  display: flex;
  align-items: center;
  svg,
  img {
    height: 1rem;
    width: 1rem;
  }
`;

export const Children = styled.div`
  display: flex;
  margin-left: ${(p) => p.theme.spacing.sm};
`;
