import { IconType } from "../Icon";

export interface AlertProps {
  label: string;
  icon?: IconType;
  onClose?: () => void;
  variant: string;
  unclosable: boolean;
}

export interface VariantProps {
  variant: string;
}

export enum Variants {
  SUCCESS = "success",
  DANGER = "danger",
  WARNING = "warning",
  INFO = "info",
}
