import styled, { DefaultTheme } from "styled-components";
import { Icon } from "../Icon";
import { ToastIconProps, ToastProps, ToastVariant } from "./Toast.types";
import { findPalette } from "../../utils/styleUtils";

type ToastPropsWithTheme = { variant: ToastVariant } & { theme: DefaultTheme };

const getVariant = (p: ToastPropsWithTheme) => {
  return findPalette(p.theme, p.variant);
};

export const Toast = styled.div<ToastProps>`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  width: 21.875rem;
  border-radius: ${(p) => p.theme.radius.radius2};
  font-size: ${(p) => p.theme.fontSize.sm};
  font-weight: ${(p) => p.theme.fontWeight.bold};
  background-color: ${(p) => p.theme.palette.layout.depth3};
  color: ${(p) => p.theme.palette.layout.depth6};
  padding: ${(p) => p.theme.spacing.xl};
  border-radius: ${(p) => p.theme.radius.radius2};
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25), 0 4px 4px rgba(0, 0, 0, 0.25);
`;

export const ToastGrouping = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: row;
`;

export const ToastHeading = styled.h1`
  font-size: ${(p) => p.theme.fontSize.sm};
  line-height: ${(p) => p.theme.lineHeight.sm};
  font-weight: ${(p) => p.theme.fontWeight.bold};
  margin-block-start: 0;
  margin-block-end: 0;
`;

export const ToastBody = styled.p`
  font-size: ${(p) => p.theme.fontSize.sm};
  line-height: ${(p) => p.theme.lineHeight.sm};
  font-weight: ${(p) => p.theme.fontWeight.regular};
  margin-block-start: 0;
  margin-block-end: 0;
`;

export const ToastIcon = styled(Icon)<ToastIconProps>`
  height: 1.5625rem;
	width: 1.5625rem;
  padding-right: ${({ theme }) => theme.spacing.md};
  color: ${(p) => getVariant(p).main};
`;
