import React from "react";
import { components } from "react-select";
import * as Styled from "./IconComponents.styles";

const { Option, ValueContainer } = components;

export const IconOption = (props: any) => {
  const { data, ...optionProps } = props;
  return (
    <Option {...optionProps}>
      <Styled.Row>
        {data.icon}
        <Styled.Children>{data.label}</Styled.Children>
      </Styled.Row>
    </Option>
  );
};

export const IconValueContainer = ({ children, ...props }: any) => {
  const value = props.getValue()?.[0];
  return (
    <ValueContainer {...props}>
      <Styled.Row>
        {value?.icon}
        <Styled.Children>{children}</Styled.Children>
      </Styled.Row>
    </ValueContainer>
  );
};
