import { DefaultTheme } from "styled-components";

export const defaultTheme: DefaultTheme = {
  name: "default",
  fontWeight: {
    regular: "400",
    bold: "700",
  },
  fontSize: {
    xl2: "1.5rem", // 24px
    xl: "1.25rem", // 20px
    lg: "1.125rem", // 18px
    md: "1rem", // 16px
    sm: "0.875rem", // 14px
    xs: "0.75rem", // 12px
    xs2: "0.625rem", // 10px
  },
  lineHeight: {
    xl2: "2rem", // 28px
    xl: "1.875rem", // 26px
    lg: "1.75rem", // 24px
    md: "1.5rem", // 22px
    sm: "1.25rem", // 20px
    xs: "1.125rem", // 18px
    xs2: "1rem", // 16px
  },
  spacing: {
    xl3: "2rem",
    xl2: "1.8rem",
    xl: "1.5rem",// 24px
    lg: "1.3rem",
    md: "1rem", // 16px
    sm: ".8rem",
    xs: ".5rem", // 8px
    xs2: ".3rem", // 5px
    xs3: ".15rem", //2px
  },
  breakpoints: {
    xs: "0",
    sm: "36rem",
    md: "48rem",
    lg: "62rem",
    xl: "75rem",
    xl2: "87.5rem",
  },
  inputElementHeight: {
    xl2: "3.5rem",
    xl: "3.0rem",
    lg: "2.5rem",
    md: "2.0rem",// 28px
    sm: "1.5rem",// 24px
    xs: "1.0rem", // 16px
    xs2: "0.5rem",// 8px
  },
  opacity: {
    opacity0: 0,
    opacity1: 0.2,
    opacity2: 0.4,
    opacity3: 0.6,
    opacity4: 0.8,
    opacity5: 1,
  },
  radius: {
    radius0: "0px",
    radius1: ".25rem",
    radius2: ".5rem", // 8px
    radius3: ".8rem",
    radius4: "1rem",
    radius5: "1.3rem",
    radius6: "50%",
  },
  palette: {
    layout: {
      depth1: "#111111",
      depth2: "#232323",
      depth3: "#393939",
      depth4: "#545454",
      depth5: "#ADADAD",
      depth6: "#FFFFFF",
      depth7: "#FFFFFF15",
    },
    lightning: {
      light: "#FFFFFF",
      dark: "#111111",
    },
    primary: {
      main: "#FFD4A3",
      contrastText: "#111111",
      dark1: "#B68F62",
      dark2: "#9F794D",
      opacity1: "#FFD4A315",
      opacity2: "#FFD4A325",
      opacity3: "#FFD4A335",
    },
    secondary: {
      main: "#FFD4A315",
      contrastText: "#FFD4A3",
      opacity1: "#FFD4A315",
      opacity2: "#FFD4A325",
      opacity3: "#FFD4A335",
    },
    success: {
      main: "#48BB78",
      contrastText: "#FFFFFF",
      dark1: "#1A9762",
      dark2: "#13764C",
      opacity1: "#48BB7815",
      opacity2: "#48BB7825",
      opacity3: "#48BB7835",
    },
    danger: {
      main: "#F5365C",
      contrastText: "#FFFFFF",
      dark1: "#B7223F",
      dark2: "#AC1836",
      opacity1: "#F5365C15",
      opacity2: "#F5365C25",
      opacity3: "#F5365C35",
    },
    info: {
      main: "#11CDEF",
      contrastText: "#FFFFFF",
      dark1: "#09859B",
      dark2: "#087B8F",
      opacity1: "#11CDEF15",
      opacity2: "#11CDEF25",
      opacity3: "#11CDEF35",
    },
    warning: {
      main: "#FF8C67",
      contrastText: "#FFFFFF",
      dark1: "#CF613E",
      dark2: "#C9512B",
      opacity1: "#FF8C6715",
      opacity2: "#FF8C6725",
      opacity3: "#FF8C6735",
    },
    attention: {
      main: "#FFFCB5",
      contrastText: "#111111",
    },
  },
};
