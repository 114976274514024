import { faChevronDown } from "@fortawesome/pro-light-svg-icons";
import React from "react";
import { components } from "react-select";
import * as Styled from "./SingleComponents.styles";

const { DropdownIndicator } = components;

export const SingleDropdownIndicator = (props: any) => {
  const { menuIsOpen, size } = props.selectProps;
  return (
    <DropdownIndicator {...props}>
      <Styled.Content $size={size}>
        <Styled.IndicatorIcon icon={faChevronDown} $isOpen={menuIsOpen} />
      </Styled.Content>
    </DropdownIndicator>
  );
};
