import React from "react";
import styled, { css } from "styled-components";
import { CombinedIconProps, CombinedStyledIconProps, CustomIconType, CustomSVGIcon } from "./Icon.types";
import { customIcons } from "./customIcons";
import { FontAwesomeIcon, FontAwesomeIconProps } from "@fortawesome/react-fontawesome";
import { bounce } from "../../utils/animations";

export function CombinedIcon(props: CombinedIconProps): JSX.Element {
  const { icon, ...rest } = props;
  const CustomIcon = customIcons[icon as CustomIconType];
  if (CustomIcon) {
    return <CustomIcon {...(rest as CustomSVGIcon)} />;
  }
  return <FontAwesomeIcon {...(props as FontAwesomeIconProps)} />;
}

export const Icon = styled(CombinedIcon)<CombinedStyledIconProps>`
  height: ${(p) => p.height || "100%"};
  width: ${(p) => p.width || "100%"};
  fill: ${(p) => p.color || p.theme.palette.layout.depth6};
  transform: rotate(${(p) => p.rotate || 0}deg);

  ${(p) =>
    p.bounce &&
    css`
      animation-name: ${bounce};
      animation-duration: 0.4s;
      animation-iteration-count: 1;
    `}
`;
