import styled from "styled-components";
import { Icon } from "../../Icon";
import { ContentProps, IndicatorIconProps } from "./MultiComponents.types";
import { getSize } from "../../../utils/size";

export const Option = styled.div`
  display: flex;
  align-items: flex-start;
`;

export const IndicatorIcon = styled(Icon)<IndicatorIconProps>`
  height: 1rem;
  width: 1rem;
  pointer-events: none;
  transition: all 0.3s ease-out;
  transform: ${(p) => (p.$isOpen ? `rotate(180deg)` : `rotate(0deg)`)};
`;

export const Content = styled.div<ContentProps>`
  display: flex;
  align-items: center;
  gap: ${(p) => p.theme.spacing.sm};
  height: ${(p) => getSize(p.$size, p.theme)};
  box-shadow: -3px 0px 4px rgba(0, 0, 0, 0.25);
  margin-right: ${(p) => p.theme.spacing.sm};
`;

export const NumSelectedValues = styled.div`
  padding-left: ${(p) => p.theme.spacing.sm};
`;
