import React, { useEffect, useState } from "react";
import GoogleMap from "google-maps-react-markers";
import styled from "styled-components";
import mapOptions, { MAX_ZOOM_LEVEL } from "./mapOptions";
import { Legend } from "./Legend";
import { MapsApi, MapWithMarkersProps, Marker } from "./Map.types";
import { renderMarkerFactory } from "./Marker/Marker";
import { MapControl } from "./Control/Control";

const GOOGLE_API_KEY = process.env.STORYBOOK_GOOGLE_API_KEY || "";

const Wrapper = styled.div`
  position: relative;
  height: 100%;
  width: 100%;
`;

const timer = (ms) => new Promise((res) => setTimeout(res, ms));

function smoothZoom(mapsApi, target, current, callback) {
  const mapCurrent = mapsApi.map.getZoom();
  if (mapCurrent === target || current >= MAX_ZOOM_LEVEL) {
    callback();
    return;
  } else {
    const z = mapsApi.map.addListener("zoom_changed", function () {
      google.maps.event.removeListener(z);
      let newCurrent = target > current ? current + 1 : current - 1;
      const zoomIsDone = current === target;
      if (zoomIsDone) {
        newCurrent = target;
      }
      smoothZoom(mapsApi, target, newCurrent, callback);
    });
    setTimeout(function () {
      mapsApi.map.setZoom(current);
    }, 200);
  }
}

const calculateDirections = (oldLocations, locations) => {
  return locations.reduce((acc, location) => {
    if (!oldLocations[location.id] || !oldLocations[location.id]?.lat || !oldLocations[location.id]?.lng)
      return acc;
    const vectorX = location.lat - oldLocations[location.id].lat;
    const vectorY = location.lng - oldLocations[location.id].lng;
    if (vectorX === 0 && vectorY === 0) {
      return { ...acc, [location.id]: oldLocations[location.id]?.direction || 0 };
    }
    const vector_length = Math.sqrt(vectorX * vectorX + vectorY * vectorY);
    const rad_angle = Math.acos(vectorX / vector_length);
    const degree_angle = (180 * rad_angle) / Math.PI;
    if (vectorY < 0) {
      return { ...acc, [location.id]: 360 - degree_angle };
    }
    return { ...acc, [location.id]: degree_angle };
  }, {});
};

export const MapWithMarkers = ({
  locations,
  shouldExtendBoundsToFitMarkers = false,
  children,
  legendItems,
  googleApiKey,
  zoom,
  center,
  numberOfAnimationFrames = 200,
}: MapWithMarkersProps) => {
  const [mapsApi, setMapsApi] = React.useState<MapsApi | null>(null);
  const _googleApiKey = googleApiKey ? googleApiKey : GOOGLE_API_KEY;
  const [isZooming, setIsZooming] = useState(false);
  const [oldLocations, setOldLocations] = useState<Record<string, Marker>>({});
  const [transientLocations, setTransientLocations] = useState([]);
  const [staticLocations, setStaticLocations] = useState([]);
  const parseLocationsToRecord = (locations: Marker<Record<string, unknown>>[]): Record<string, Marker> => {
    return locations
      .filter((location) => location.id !== undefined) // TODO: this filter on undefined id is to prevent rotation of markers without id, I suggest we do it some other way, maybe by passing a prop to the marker
      .reduce((state, location) => {
        state[location.id] = location;
        return state;
      }, {});
  };

  const handleZoomAnimationEnd = () => {
    setIsZooming(false);
  };
  useEffect(() => {
    if (zoom) {
      if (mapsApi && mapsApi.map && !isZooming && zoom !== mapsApi.map.getZoom() && mapsApi.map.getZoom()) {
        if (zoom >= MAX_ZOOM_LEVEL) return mapsApi.map.setZoom(MAX_ZOOM_LEVEL);
        setIsZooming(true);
        smoothZoom(mapsApi, zoom, mapsApi.map.getZoom(), () => setIsZooming(false));
      }
    }
  }, [isZooming, zoom]);

  const [memoLocations, setMemoLocations] = useState(locations);

  useEffect(() => {
    const locationsAreTheSame = locations.every((location) => {
      const oldLocation = memoLocations.find((loc) => loc.id === location.id);
      if (location.id === undefined) return true;
      if (!oldLocation) return false;
      return oldLocation?.lat === location.lat || oldLocation?.lng === location.lng;
    });
    setMemoLocations(locations);
    if (locationsAreTheSame) return;

    const updateLocations = async (directions: Record<string, number>) => {
      for (let i = 0; i < numberOfAnimationFrames; i++) {
        const updatedLocations = Object.entries(directions).map(([directionId, direction]) => {
          const location = locations.find((loc) => loc.id === directionId);
          if (!location?.id) {
            return;
          }
          const initialLat = oldLocations[location.id]?.lat;
          const initialLng = oldLocations[location.id]?.lng;
          const deltaLat = (location.lat - initialLat) / numberOfAnimationFrames;
          const deltaLng = (location.lng - initialLng) / numberOfAnimationFrames;
          return {
            ...location,
            lat: initialLat + deltaLat * i,
            lng: initialLng + deltaLng * i,
            direction,
          };
        });
        setTransientLocations(updatedLocations);
        await timer(Math.floor(3000 / numberOfAnimationFrames)); // HACK this is to make the animation smoother, I'm assuming that the location update interval is 3s
      }
      setOldLocations(
        parseLocationsToRecord(
          locations.map((location) => {
            location.direction = directions[location.id];
            return location;
          })
        )
      );
    };

    if (Object.keys(oldLocations).length === 0) {
      const staticLoc = locations.filter((location) => !location.id);
      const transientLoc = locations.filter((location) => location.id);
      const locationsToRecord = parseLocationsToRecord(locations);
      setOldLocations(locationsToRecord);
      setStaticLocations(staticLoc);
      setTransientLocations(transientLoc);
      return;
    }

    const directions = calculateDirections(oldLocations, locations);
    const staticLoc = locations.filter((location) => !location?.id);
    updateLocations(directions);
    setStaticLocations(staticLoc);
  }, [locations]);

  return (
    <Wrapper id="map-wrapper">
      <GoogleMap
        apiKey={_googleApiKey}
        options={mapOptions}
        defaultZoom={zoom ? zoom : mapOptions.zoom}
        defaultCenter={center ? center : mapOptions.center}
        onGoogleApiLoaded={setMapsApi}
        onZoomAnimationEnd={handleZoomAnimationEnd}>
        {mapsApi &&
          transientLocations &&
          transientLocations.map(renderMarkerFactory(mapsApi, shouldExtendBoundsToFitMarkers))}
        {mapsApi &&
          staticLocations &&
          staticLocations.map(renderMarkerFactory(mapsApi, shouldExtendBoundsToFitMarkers))}
      </GoogleMap>
      {!isZooming && <Legend items={legendItems} />}
      {mapsApi && !isZooming && (
        <MapControl
          mapApi={mapsApi}
          shouldExtendBoundsToFitMarkers={shouldExtendBoundsToFitMarkers}
          locations={locations}
        />
      )}
      {children}
    </Wrapper>
  );
};
