import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { ToastProps } from "./Toast.types";
import { Button } from "../Button";
import * as Styles from "./Toast.styles";
import {
  faTrafficCone,
  faThumbsUp,
  faTriangleExclamation,
  faInfoCircle,
  faXmarkLarge,
} from "@fortawesome/pro-light-svg-icons";

const StyledCloseButton = styled(Button)`
  &&& {
    margin: 0;
    color: ${(p) => p.theme.palette.lightning.light};
  }
`;

const getDefaultIcon = (variant: string) => {
  switch (variant) {
    case "Danger":
      return faTriangleExclamation;
    case "Warning":
      return faTrafficCone;
    case "Info":
      return faInfoCircle;
    case "Success":
      return faThumbsUp;
  }
};

export const Toast = (props: ToastProps) => {
  const {
    variant = "Primary",
    content = { title: "Title", body: "Body" },
    customIcon,
    showIcon = false,
    autoHideDuration = 30000,
    isClosable = false,
  } = props;
  const [isOpen, setIsOpen] = useState(true);
  const iconToRender = customIcon || getDefaultIcon(variant);

  useEffect(() => {
    if (isOpen) {
      setTimeout(() => {
        setIsOpen(false);
      }, autoHideDuration);
    }
  }, [isOpen]);

  if (!isOpen) return null;
  return (
    <Styles.Toast {...props} variant={variant}>
      <Styles.ToastGrouping>
        {showIcon && iconToRender && <Styles.ToastIcon icon={iconToRender} variant={variant} />}
        <div>
          <Styles.ToastHeading>{content.title} </Styles.ToastHeading>
          <Styles.ToastBody> {content.body} </Styles.ToastBody>
        </div>
      </Styles.ToastGrouping>
      {isClosable && <StyledCloseButton kind="Ghost" icon={faXmarkLarge} onClick={() => setIsOpen(false)} />}
    </Styles.Toast>
  );
};
