import styled from "styled-components";

export const LegendBackground = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  margin: ${({ theme }) => theme.spacing.md} ${({ theme }) => theme.spacing.md}
    ${({ theme }) => theme.spacing.md} ${({ theme }) => theme.spacing.sm};
  padding: ${({ theme }) => theme.spacing.xs};

  background: ${({ theme }) => theme.palette.layout.depth2}66;
  border-radius: 5px;
`;

export const LegendItem = styled.div<{ color: string }>`
  display: flex;
  align-items: center;
  font-size: ${({ theme }) => theme.fontSize.xs2};
  color: ${({ theme }) => theme.palette.layout.depth6};
  :not(:last-child) {
    margin-bottom: ${({ theme }) => theme.spacing.xs2};
  }
  ::before {
    content: "";
    border-radius: 50%;
    background-color: ${({ color }) => color ?? "transparent"};
    width: ${({ theme }) => theme.spacing.xs};
    height: ${({ theme }) => theme.spacing.xs};
    margin-right: ${({ theme }) => theme.spacing.xs};
  }
`;
