import React, { useEffect, useRef, useState } from "react";
import { ModalProps } from "./Modal.types";
import * as Styled from "./Modal.styles";
import { Button } from "../Button";
import { faXmarkLarge } from "@fortawesome/pro-light-svg-icons";
import styled from "styled-components";
import { useClickedOutside } from "../../hooks/useClickedOutside";
import { useHasPressedKey } from "../../hooks/useHasPressedKey";
import FocusLock from "react-focus-lock";

const StyledCloseButton = styled(Button)`
  &&& {
    position: absolute;
    top: ${(p) => p.theme.spacing.xs};
    right: ${(p) => p.theme.spacing.xs};
    color: ${(p) => p.theme.palette.lightning.light};
    margin: 0;
  }
`;

const StyledPrimaryButton = styled(Button)`
  margin-left: ${(p) => p.theme.spacing.md};
`;

const StyledSecondaryButton = styled(Button)``;

export const Modal = (props: ModalProps) => {
  const {
    size = "Default",
    open = true,
    icon,
    firstButton,
    secondButton,
    scrollable,
    contentInCenter = false,
    zIndex,
    onClose,
  } = props;
  const [isOpen, setIsOpen] = useState(open);
  const ref = useRef<HTMLDivElement>(null);
  const hasClickedOutside = useClickedOutside(ref);
  const hasPressedKey = useHasPressedKey(ref, "Escape");

  useEffect(() => {
    if (hasClickedOutside || hasPressedKey) {
      handleClose();
    }
  }, [hasClickedOutside, hasPressedKey]);

  useEffect(() => {
    isOpen ? (document.body.style.overflow = "hidden") : (document.body.style.overflow = "unset");
  }, [isOpen]);

  const handleClose = () => {
    onClose();
    setIsOpen(false);
  };

  return (
    <Styled.ModalOverlay open={isOpen} zIndex={zIndex}>
      <FocusLock>
        <Styled.ModalCard {...props} size={size} onClick={() => hasClickedOutside} ref={ref}>
          <Styled.ModalScroll scrollable={scrollable}>
            <Styled.ModalHeader>
              {icon && <Styled.ModalIcon icon={icon} />}
              {props.title}
              <StyledCloseButton kind="Ghost" onClick={handleClose} icon={faXmarkLarge} />
            </Styled.ModalHeader>
            <Styled.ModalBody size={size} contentInCenter={contentInCenter}>
              {props.body}
            </Styled.ModalBody>
          </Styled.ModalScroll>
          <Styled.ModalFooter>
            {secondButton?.onClick && <StyledSecondaryButton variant="Secondary" {...secondButton} />}
            {firstButton?.onClick && <StyledPrimaryButton variant="Primary" {...firstButton} />}
          </Styled.ModalFooter>
        </Styled.ModalCard>
      </FocusLock>
    </Styled.ModalOverlay>
  );
};
