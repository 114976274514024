import React from "react";
import { StyledContainer, StyledIcon, StyledNumberSelector } from "./NumberSelector.style";
import { faChevronDown, faChevronUp } from "@fortawesome/pro-light-svg-icons";
import { NumberSelectorType } from "./NumberSelector.types";

export const NumberSelector = (props: NumberSelectorType) => {
  const { roundedBorders, onStepUp, onStepDown } = props;
  return (
    <StyledContainer roundedBorders={roundedBorders}>
      <StyledNumberSelector
        onClick={() => onStepUp()}
        data-testid="step-up"
        type="button"
        disabled={props.disabled}
        roundedBorders={props.roundedBorders}>
        <StyledIcon icon={faChevronUp} />
      </StyledNumberSelector>
      <StyledNumberSelector
        onClick={() => onStepDown()}
        type="button"
        data-testid="step-down"
        disabled={props.disabled}
        roundedBorders={props.roundedBorders}>
        <StyledIcon icon={faChevronDown} />
      </StyledNumberSelector>
    </StyledContainer>
  );
};
