import React, { Fragment } from "react";
import styled from "styled-components";
import { defaultTheme } from "../../theme";
import { Spinner } from "../Spinner";
import * as Styled from "./Button.styles";
import { ButtonKind, ButtonProps } from "./Button.types";

const RightIcon = styled(Styled.Icon)`
  margin-right: ${({ theme }) => theme.spacing.md};
`;

const LeftIcon = styled(Styled.Icon)`
  margin-left: ${({ theme }) => theme.spacing.md};
`;
export const Button = (props: ButtonProps) => {
  const {
    fullWidth = false,
    size = "Large",
    kind = "Solid",
    type = "button",
    variant = getDefaultVariant(kind),
    disabled = false,
    iconRight,
    iconLeft,
    icon,
    label,
    title,
    children,
    isLoading,
  } = props;

  const renderContent = () => {
    if (children) {
      return children;
    }
    return kind !== "Circular" && title?.toUpperCase();
  };

  return (
    <Styled.Container fullWidth={fullWidth} label={label}>
      <Styled.Button type={type} fullWidth={fullWidth} size={size} variant={variant} kind={kind} {...props}>
        {isLoading ? (
          <Spinner color={defaultTheme.palette.layout.depth1} height={"1.5rem"} />
        ) : (
          <Fragment>
            {iconLeft && kind !== "Circular" && <RightIcon icon={iconLeft} />}
            {icon && <Styled.Icon icon={icon} />}
            {renderContent()}
            {iconRight && kind !== "Circular" && <LeftIcon icon={iconRight} />}
          </Fragment>
        )}
      </Styled.Button>
      {label && <Styled.BottomLabel disabled={disabled}>{label.toUpperCase()}</Styled.BottomLabel>}
    </Styled.Container>
  );
};

const getDefaultVariant = (kind: ButtonKind) => {
  switch (kind) {
    case "Solid":
      return "Primary";
    case "Outline":
      return "Secondary";
    case "Ghost":
      return "Secondary";
    case "Circular":
    default:
      return "Primary";
  }
};
