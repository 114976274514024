import styled, { DefaultTheme } from "styled-components";
import { findPalette } from "../../utils/styleUtils";
import { InfoTabProps, InfoTabVariant } from "./InfoTab.types";

type InfoTabPropsWithTheme = { variant: InfoTabVariant } & { theme: DefaultTheme };

const getVariant = (p: InfoTabPropsWithTheme) => {
  return findPalette(p.theme, p.variant);
};

export const InfoTab = styled.div<InfoTabProps>`
  background: ${(p) => (p.variant === "Default" ? p.theme.palette.layout.depth7 : getVariant(p).opacity1)};
  color: ${(p) => p.theme.palette.layout.depth6};
  font-size: ${(p) => p.theme.fontSize.sm};
  text-align: center;
  border-radius: ${(p) => p.theme.radius.radius2};
  padding: ${(p) => `${p.theme.spacing.xs} ${p.theme.spacing.md}`};
  white-space: nowrap;
  width: fit-content;
`;

export const InfoTabContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Circle = styled.div<{ variant: InfoTabVariant }>`
  border-radius: ${(p) => p.theme.radius.radius6};
  background-color: ${(p) => (p.variant === "Default" ? p.theme.palette.layout.depth6 : getVariant(p).main)};
  height: 0.625rem;
  width: 0.625rem;
  margin-right: 0.625rem;
`;
