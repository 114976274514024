import styled, { css } from "styled-components";

export const RadioGroup = styled.div`
  display: flex;
  flex-direction: column;
`;

export const RadioGroupWrapper = styled.fieldset`
  border: none;
  margin-inline-start: 0;
  margin-inline-end: 0;
  padding-block-start: 0;
  padding-inline-start: 0;
  padding-inline-end: 0;
  padding-block-end: 0;
  margin-bottom: ${(p) => p.theme.spacing.md};
`;

export const RadioGroupLabel = styled.legend`
  color: ${(p) => p.theme.palette.layout.depth6};
  font-size: ${(p) => p.theme.fontSize.sm};
  line-height: ${(p) => p.theme.lineHeight.sm};
  padding-bottom: ${(p) => p.theme.spacing.xs};
  padding-inline-start: 0;
  padding-inline-end: 0;
`;

export const Required = styled.span`
  color: ${(p) => p.theme.palette.danger.main};
  margin-left: 4px;
`;

export const BottomRadioGroupLabel = styled.label`
  margin-top: ${(p) => p.theme.spacing.xs};
  font-size: ${(p) => p.theme.fontSize.xs};
  font-weight: 400;
  line-height: ${(p) => p.theme.lineHeight.xs};
  color: ${(p) => p.theme.palette.danger.main};

  ${css`
    ::after {
      content: " ";
      white-space: pre;
    }
  `}
`;
