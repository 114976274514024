import styled from "styled-components";
import { RHFSwitchHandleProps, RHFSwitchContainerProps, RHFSwitchComponentProps } from "./RHFSwitch.types";

export const RHFSwitchContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const RHFSwitch = styled.div<RHFSwitchHandleProps>`
  width: 2.25rem;
  height: 1.125rem;
  margin-right: ${(p) => p.theme.spacing.sm};
  position: relative;
  border-radius: ${(p) => p.theme.radius.radius5};
  border: 1px solid ${(p) => (p.toggled ? p.theme.palette.primary.main : p.theme.palette.layout.depth4)};
  background-color: ${(p) => (p.toggled ? p.theme.palette.primary.main : p.theme.palette.layout.depth3)};
  transition: all 400ms ease-in-out;
`;

export const RHFSwitchHandle = styled.div<RHFSwitchHandleProps>`
  position: absolute;
  top: 2px;
  left: ${(p) => (p.toggled ? "20px" : "2px")};
  border-radius: ${(p) => p.theme.radius.radius6};
  height: 0.875rem;
  width: 0.875rem;
  transition: all 400ms;
  background-color: ${(p) =>
    p.toggled ? p.theme.palette.primary.contrastText : p.theme.palette.layout.depth6};
`;

export const Label = styled.label<RHFSwitchContainerProps>`
  position: relative;
  width: fit-content;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  border: none;
  cursor: ${(p) => (p.disabled ? "not-allowed" : "pointer")};
  font-size: ${(p) => p.theme.fontSize.sm};
  color: ${(p) =>
    p.disabled && !p.readOnly ? p.theme.palette.layout.depth5 : p.theme.palette.layout.depth6};
`;

export const HiddenCheckbox = styled.input.attrs({ type: "checkbox" })<RHFSwitchComponentProps>`
  border: 0;
  clip: rect(0 0 0 0);
  clippath: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;
`;

export const BottomLabel = styled.p`
  margin-top: ${(p) => p.theme.spacing.xs};
  font-size: ${(p) => p.theme.fontSize.xs};
  font-weight: ${(p) => p.theme.fontWeight.regular};
  line-height: ${(p) => p.theme.lineHeight.xs};
  color: ${(p) => p.theme.palette.danger.main};
`;

export const Required = styled.span`
  color: ${(p) => p.theme.palette.danger.main};
  margin-left: 4px;
`;
