import styled from "styled-components";
import { DividerProps, DividerVariant } from "./Divider.types";

const getBorder = (variant: DividerVariant) => {
  switch (variant) {
    case "Line":
      return "solid";
    case "Dots":
      return "dashed";
    default:
      return "solid";
  }
};

export const StyledDivider = styled.hr<DividerProps>`
  width: ${(p) => (p.width ? p.width : "100%")};
  border: ${(p) => p.theme && p.variant && `1px ${getBorder(p.variant)} ${p.theme.palette.layout.depth4} `};
`;
